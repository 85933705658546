// DateTimeFormat polyfill to get formatRange in Safari
import "@formatjs/intl-datetimeformat/polyfill";
import "@formatjs/intl-datetimeformat/locale-data/en"; // locale-data for en
import "@formatjs/intl-datetimeformat/add-all-tz"; // Add ALL tz data

import "../styles/globals.css";
import React from "react";
import { IntlProvider } from "react-intl";
import { useRouter } from "next/router";
import Head from "next/head";
import { OverlayProvider } from "@react-aria/overlays";
import { SSRProvider } from "@react-aria/ssr";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { UserProvider as Auth0UserProvider } from "@auth0/nextjs-auth0";
import { Provider as UserProvider } from "../user-context";
import { Provider as HostSharedStateProvider } from "../host-context";
import { PRODUCTION_BASE_PATH } from "../constants/misc";
import * as Fathom from "fathom-client";

const title = "Satchel | Too much office space? We help you get paid for it.";
const description =
  "Satchel is flexible subleasing on autopilot. We take care of the entire process — from NDA and insurance, to finding you trusted tenants.";
const canonicalUrl = PRODUCTION_BASE_PATH;
const image = `${PRODUCTION_BASE_PATH}/metatag.png`;

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  React.useEffect(() => {
    Fathom.load("FPSJZIXC", {
      includedDomains: ["heysatchel.com", "www.heysatchel.com"],
    });

    const handleRouteChange = (url) => {
      window.gtag?.("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
        page_path: url,
      });
      window.analytics.page();
      Fathom.trackPageview();
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="icon" href="/favicon.ico" />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />

        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:url" content={PRODUCTION_BASE_PATH} key="og:url" />
        <meta property="og:title" content={title} key="og:title" />
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
        <meta property="og:image" content={image} key="og:image" />

        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta property="twitter:url" content={canonicalUrl} key="twitter:url" />
        <meta property="twitter:title" content={title} key="twitter:title" />
        <meta
          property="twitter:description"
          content={description}
          key="twitter:description"
        />
        <meta property="twitter:image" content={image} key="twitter:image" />

        {process.env.NODE_ENV !== "production" ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
                analytics.load("${process.env.segmentApiKey}");
                analytics.page();
                }}();`,
            }}
          />
        ) : (
          <>
            {/* Facebook Pixel */}
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${process.env.facebookPixelId}');
      fbq('track', 'PageView');`,
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${process.env.facebookPixelId}&ev=PageView&noscript=1" />`,
              }}
            />

            {/* Google Analytics */}
            <script
              async
              src={
                "https://www.googletagmanager.com/gtag/js?id=" +
                process.env.googleAnalyticsId
              }
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.googleAnalyticsId}');`,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
                analytics.load("${process.env.segmentApiKey}");
                analytics.page();
                }}();`,
              }}
            />

            <script
              dangerouslySetInnerHTML={{
                __html: `!function(){var e=window.Cohere=window.Cohere||[];if(e.invoked)console.error("Tried to load Cohere twice");else{e.invoked=!0,e.snippet="0.2",e.methods=["init","identify","stop","showCode"],e.methods.forEach(function(o){e[o]=function(){var t=Array.prototype.slice.call(arguments);t.unshift(o),e.push(t)}});var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://static.cohere.so/main.js",o.crossOrigin="anonymous";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(o,t)}}();
              window.Cohere.init("H4hxJiudYGX-cc6zjp5EyxWf");`,
              }}
            />
          </>
        )}
      </Head>

      <IntlProvider locale="en-US">
        <SSRProvider>
          <OverlayProvider>
            <Auth0UserProvider>
              <UserProvider>
                <HostSharedStateProvider>
                  <Elements stripe={stripePromise}>
                    <Component {...pageProps} />
                  </Elements>
                </HostSharedStateProvider>
              </UserProvider>
            </Auth0UserProvider>
          </OverlayProvider>
        </SSRProvider>
      </IntlProvider>
    </>
  );
}

export default MyApp;
