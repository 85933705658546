export const WIFI = "Enterprise wifi";
export const MEETING_ROOMS = "Meeting rooms";
export const CONFERENCE_ROOMS = "Conference rooms";
export const PHONE_BOOTHS = "Phone booths";
export const WORKCHAIRS = "Comfortable workchairs";
export const STANDING_DESKS = "Standing desks";
export const FREE_BEVERAGES = "Free beverages (coffee/tea/water)";
export const ALL_DAY_ACCESS = "24h access";
export const VENDING_MACHINES = "Vending machines";
export const SELF_CHECK_IN = "Self check-in";
export const PET_FRIENDLY = "Pet friendly";
export const WHITEBOARD = "Whiteboard";
export const PRINTER = "Printer";
export const LOUNGE = "Lounge area";
export const KITCHEN = "Kitchen";
export const FREE_PARKING = "Free parking";
export const RECEPTION = "Reception";
export const AIR_CONDITIONER = "A/C";
export const OFFICE_MANAGER = "Office Manager";
export const MAIL_HANDLING = "Mail and package handling";
export const GYM = "Gym";
export const PING_PONG = "Ping-pong table";
export const BOARD_GAMES = "Board games";
