import marked from "marked";
import stripMarkdown from "remove-markdown";
import {
  OPEN_OFFICE_AREA,
  MEETING_ROOM,
  PHONE_BOOTH,
  KITCHEN,
  RECEPTION,
  LOUNGE_AREA,
  PRIVATE_OFFICE,
} from "./constants/room-types";
import * as amenities from "./constants/amenities";

const defaultAmenities = [
  amenities.WIFI,
  amenities.ALL_DAY_ACCESS,
  amenities.STANDING_DESKS,
  amenities.WORKCHAIRS,
];

const listings = [
  {
    title: "The Muse",
    slug: "the-muse-broadway",
    summary:
      "Ideal office for smaller teams looking for workspace or a private office in the heart of Manhattan. You’ll have access to meeting rooms in different sizes, private phone booths, lounge areas, and a fully equipped kitchen.",
    shortAddress: "Midtown Manhattan, New York",
    address1: "1375 Broadway, 20th Floor",
    address2: "New York, NY 10018",
    cover: "/promo/the-muse-broadway/cover.jpg",
    images: Array.from({ length: 9 }).map((_, n) => ({
      url: `/promo/the-muse-broadway/${n}.jpg`,
    })),
    coords: [40.7526208, -73.9900699],
    matterportUrl: "https://my.matterport.com/show/?m=z31umMx5uEY",
    host: {
      name: "The Muse",
      title: "",
      avatarUrl: "/promo/the-muse-broadway/host.png",
      url: "https://www.themuse.com/",
      contact: "or.lapid@themuse.com",
    },
    capacity: 10,
    rating: 4.6,
    reviewCount: 9,
    similarLocations: [],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 10 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: PHONE_BOOTH, count: 4 },
      { type: KITCHEN },
      { type: LOUNGE_AREA },
    ],
    amenities: [
      amenities.WIFI,
      amenities.ALL_DAY_ACCESS,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.STANDING_DESKS,
      amenities.LOUNGE,
      amenities.KITCHEN,
      amenities.CONFERENCE_ROOMS,
      amenities.MEETING_ROOMS,
      amenities.PHONE_BOOTHS,
      amenities.BOARD_GAMES,
    ],
    accessInstructions:
      "Access to the space is provided on-demand via the Kisi app. You'll receive further instructions via email.",
    available: true,
    listed: true,
    area: "ny",
    upcoming: false,
    wifi: {
      ssid: "TheMuse",
      password: "DoTh3Impossibl3",
    },
    floorMap: "/promo/the-muse-broadway/floor-map.png",
    officeRules: `**Bathrooms**: Bathrooms are available at the extremities of the office.

**Kitchen**: The kitchen is available to you and fully stocked with plates, glasses, and utensils. There is also a Keurig coffee machine with pods that you are welcome to use.

**Meeting rooms**: You are free to use any meeting room or phone booth when available.

**Covid-19**:  You'll have to fill out the form on the ipad at the entrance to the office _every day_. Please maintain social distance while using the space and wear a mask if you're not vaccinated.

**Misc. / Other**: If any other requests or problems, please contact Or ([or.lapid@themuse.com](mailto:or.lapid@themuse.com))
`,
  },
  {
    title: "flexEngage",
    slug: "flex-engage-orlando",
    summary:
      "Modern office suite in Orlando's Downtown Central Business District. A very spacious space with a couple of meeting rooms, kitchen and a lounge area, perfect for a few small teams to work together. Parking is not part of the monthly price, but can be requested if needed, via the building manager.",
    shortAddress: "Downtown Orlando",
    address1: "Orlando, FL 32801",
    cover: "/promo/flex-engage-orlando/cover.jpg",
    images: Array.from({ length: 8 }).map((_, n) => ({
      url: `/promo/flex-engage-orlando/${n}.jpg`,
    })),
    coords: [28.5400755, -81.3802395],
    host: {
      name: "flexEngage",
      title: "",
      avatarUrl: "/promo/flex-engage-orlando/host.jpg",
      url: "https://www.flexengage.com",
    },
    capacity: 40,
    amenities: [
      amenities.WIFI,
      amenities.ALL_DAY_ACCESS,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.VENDING_MACHINES,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 40 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: KITCHEN },
      { type: LOUNGE_AREA },
    ],
    accessInstructions:
      "Keys will be given upon your first visit. You will receive further instructions via email.",
    available: true,
    upcoming: false,
    listed: true,
  },
  {
    title: "weweb.io",
    slug: "weweb-paris",
    summary: "Cozy office space in Paris on the 13th arrondissement.",
    shortAddress: "13th arrondissement, Paris",
    address1: "68 Rue du Dessous des Berges",
    address2: "75013 Paris, France",
    cover: "/spaces/weweb-paris/cover.jpg",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/weweb-paris/${n + 1}.jpg`,
    })),
    coords: [48.8280531, 2.3670613],
    host: {
      name: "weweb.io",
      title: "",
      avatarUrl: "/spaces/weweb-paris/host.jpeg",
      url: "https://www.weweb.io/",
    },
    listed: true,
    area: "paris",
  },
  {
    title: "Voi",
    slug: "voi-sthlm",
    address1: "Norrmalm, Stockholm",
    address2: "",
    cover: "/spaces/voi-sthlm/thumb-cover.jpg",
    images: [],
    // coords: [],
    host: {
      name: "Voi",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    // capacity: 11,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    // access: "Access to the space is provided on-demand via Kisi's app",
    available: false,
    listed: true,
    area: "stockholm",
    upcoming: false,
    landingDisclaimer: "(Coming soon)",
  },
  {
    title: "FLDWRK",
    slug: "fldwrk-fullerton",
    address1: "Fullerton, CA",
    address2: "",
    cover: "/spaces/fldwrk-fullerton/cover.jpeg",
    images: Array.from({ length: 11 }).map((_, i) => ({
      url: `/spaces/fldwrk-fullerton/${i + 1}.jpeg`,
    })),
    // coords: [48.8280531, 2.3670613],
    // coords: [],
    host: {
      name: "FLDWRK",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    // capacity: 11,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    // access: "Access to the space is provided on-demand via Kisi's app",
    available: false,
    upcoming: false,
    listed: true,
    area: "la",
  },
  {
    title: "NO PICNIC",
    slug: "nopicnic-sthlm",
    summary:
      "Historic space in the center of Stockholm with plenty of desks, meeting rooms and even private office space.",
    address1: "Östermalm, Stockholm",
    address2: "",
    cover: "/spaces/nopicnic-sthlm/cover.jpg",
    images: Array.from({ length: 5 }).map((_, n) => ({
      url: `/spaces/nopicnic-sthlm/${n}.jpg`,
    })),
    coords: [59.335337, 18.0848586],
    host: {
      name: "NO PICNIC",
      // title: "",
      avatarUrl: "/logos/nopicnic.png",
      url: "https://www.nopicnic.com/",
    },
    capacity: 35,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    amenities: [
      amenities.WIFI,
      amenities.ALL_DAY_ACCESS,
      amenities.WORKCHAIRS,
      amenities.SELF_CHECK_IN,
    ],
    accessInstructions:
      "Access to the space is provided via an access key received on first visit. You'll receive further instructions via email.",
    available: false,
    upcoming: false,
    listed: true,
    area: "stockholm",
  },
  {
    title: "Bontouch",
    slug: "bontouch-ny",
    address1: "Lower Manhattan, New York",
    address2: "",
    cover: "/spaces/bontouch-ny/cover.jpg",
    images: [],
    // coords: [48.8280531, 2.3670613],
    coords: [40.72392943706261, -73.99988517312627],
    host: {
      name: "Bontouch",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    capacity: 11,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    // access: "Access to the space is provided on-demand via Kisi's app",
    available: false,
    upcoming: false,
    listed: false,
    area: "ny",
    landingDisclaimer: "(Coming soon)",
  },
  {
    title: "Feather",
    slug: "feather-ny",
    summary:
      "Enjoy the relaxed vibe and productive atmosphere at Feather's beautiful office, in SoHo. The natural light from the large windows, combined with a high ceiling, makes this one of the brightest locations we have.",
    address1: "SoHo, New York", // 459 Broadway?
    address2: "",
    cover: "/spaces/feather-ny/cover.jpg",
    images: [{ url: "/spaces/feather-ny/1.jpg" }],
    coords: [40.721075780349516, -74.00098482848622], // TOOD
    host: {
      name: "Feather",
      // title: "",
      avatarUrl: "/logos/feather.svg",
      summary: "Easy furniture rental with white glove delivery & assembly",
      url: "https://www.livefeather.com",
    },
    capacity: 40,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    // access: "Access to the space is provided on-demand via Kisi's app",
    available: false,
    upcoming: false,
    listed: false,
    area: "ny",
    accessInstructions:
      "A key card will be provided upon your first visit. You will receive further instructions via email.",
  },
  {
    title: "Nested",
    slug: "nested-london",
    address1: "Farringdon, London",
    address2: "",
    cover: "/spaces/nested-london/cover.jpg",
    images: [
      // { url: "/spaces/nested-london/1.jpg" },
      // { url: "/spaces/nested-london/2.jpg" },
    ],
    coords: [51.521772435103124, -0.10821378465877979],
    host: {
      name: "Nested",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    capacity: 10,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: false,
    upcoming: false,
    listed: true,
    area: "london",
  },
  {
    title: "Osynlig",
    slug: "osynlig-stockholm",
    shortAddress: "Södermalm, Stockholm",
    address1: "Klevgränd 7, 116 46 Stockholm",
    cover: "/spaces/osynlig-stockholm/cover.jpg",
    images: [
      { url: "/spaces/osynlig-stockholm/1.jpg" },
      { url: "/spaces/osynlig-stockholm/2.jpg" },
      { url: "/spaces/osynlig-stockholm/3.jpg" },
    ],
    coords: [59.31884768816251, 18.07518664907316],
    host: {
      name: "Osynlig",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 2 },
      { type: MEETING_ROOM, capacity: 7 },
      { type: MEETING_ROOM, capacity: 6 },
    ],
    capacity: 2,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: false,
    upcoming: true,
    listed: true,
    area: "stockholm",
  },
  {
    title: "Grebban Design Agency",
    slug: "grebban-stockholm",
    shortAddress: "Norrmalm, Stockholm",
    address1: "Lilla Bantorget 11, 111 23 Stockholm",
    cover: "/spaces/grebban-stockholm/cover.jpg",
    images: [],
    coords: [59.33562395620551, 18.051456854374038],
    host: {
      name: "Grebban Design Agency",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    capacity: 2,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: false,
    upcoming: true,
    listed: true,
    area: "stockholm",
  },
  {
    title: "Grebban Design Agency",
    slug: "grebban-gothenburg",
    shortAddress: "Vasastan, Gothenburg",
    address1: "Viktoriagatan 16, 411 24 Gothenburg",
    cover: "/spaces/grebban-gothenburg/cover.jpg",
    images: [
      { url: "/spaces/grebban-gothenburg/1.jpg" },
      { url: "/spaces/grebban-gothenburg/2.jpg" },
      { url: "/spaces/grebban-gothenburg/3.jpg" },
      { url: "/spaces/grebban-gothenburg/4.jpg" },
      { url: "/spaces/grebban-gothenburg/5.jpg" },
      { url: "/spaces/grebban-gothenburg/6.jpg" },
    ],
    coords: [57.69785548090714, 11.966095055771893],
    host: {
      name: "Grebban Design Agency",
      // title: "",
      // avatarUrl: "/promo/tothepoint-london/host.jpeg",
    },
    capacity: 2,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: false,
    upcoming: true,
    listed: true,
    // area: "gothenburg",
  },
  {
    title: "Newfront Insurance",
    slug: "newfront-sf",
    shortAddress: "SoMa, San Francisco",
    // address1: "Surbrunnsgatan 44A",
    // address2: "113 48 Stockholm",
    cover: "/spaces/newfront-insurance-sf/cover.jpeg",
    images: [],
    coords: [37.78953936814661, -122.40070180472264],
    host: {
      name: "Newfront Insurance",
      // title: "",
      avatarUrl: "/logos/newfront-insurance.svg",
    },
    capacity: 40,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: false,
    upcoming: false,
    listed: true,
    area: "sf",
    landingDisclaimer: "(Coming soon)",
  },
  {
    title: "The Charlotte Road",
    slug: "the-charlotte-road-london",
    summary: `Spacious and light 2nd floor office space in central Shoreditch, just a short walk from both Old Street & Shoreditch High Street Station. This contemporary office can accommodate up to 16 people in the main office with a private meeting space for up to 8.

Equipped with state of the art technology including Zoom calling, speedy wifi and a video door phone this office has everything you need to make it the perfect environment for your team to thrive.`,
    shortAddress: "Charlotte Road, London",
    address1: "57-60 Charlotte Road",
    address2: "Hackney EC2A3QT",
    cover: "/spaces/charlotte-road/cover.jpg",
    images: [
      { url: "/spaces/charlotte-road/1.jpg" },
      { url: "/spaces/charlotte-road/2.jpg" },
      { url: "/spaces/charlotte-road/3.jpg" },
      { url: "/spaces/charlotte-road/4.jpg" },
      { url: "/spaces/charlotte-road/5.jpg" },
    ],
    coords: [51.52662026607385, -0.0813580057006919],
    host: {
      name: "Adorna Group",
      // title: "",
      avatarUrl: "/logos/adorna-group.png",
      url: "https://www.adorna.group/",
    },
    capacity: 16,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: true,
    upcoming: false,
    listed: true,
    area: "london",
    accessInstructions:
      "Access to the space is provided via fob and key sets handed upon first visit. Please liaise with your host Charlie (+44 7966 423618) to agree on time to drop-by.",
    amenities: [
      amenities.WORKCHAIRS,
      amenities.WIFI,
      amenities.PHONE_BOOTHS,
      amenities.PET_FRIENDLY,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 16 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: KITCHEN },
      { type: LOUNGE_AREA },
    ],
  },
  {
    title: "CC Projects",
    slug: "cc-projects-wallinggatan",
    summary: `4 desk spaces available for rent in this spacious creative top floor office on Wallingatan, 3 minutes from the Hötorget subway. Great fit for a small team or remote workers looking for a change of scenery.

The office is located in the Stockholm city centre, close to the subway, and with many local amenities — such as shops, bars, and restaurants.`,
    shortAddress: "Norrmalm, Stockholm",
    address1: "Wallingatan 3B",
    address2: "111 60, Stockholm",
    cover: "/spaces/cc-projects-stockholm/cover.jpeg",
    images: [
      { url: "/spaces/cc-projects-stockholm/1.jpeg" },
      { url: "/spaces/cc-projects-stockholm/2.jpeg" },
      { url: "/spaces/cc-projects-stockholm/3.jpeg" },
      { url: "/spaces/cc-projects-stockholm/4.jpeg" },
    ],
    coords: [59.33758592589475, 18.058805656344024],
    host: {
      name: "CC Projects",
      avatarUrl: "/spaces/cc-projects-stockholm/host.png",
      url: "https://ccprojects.se/",
    },
    capacity: 4,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: true,
    upcoming: false,
    listed: true,
    area: "stockholm",
    amenities: [
      amenities.WIFI,
      amenities.MEETING_ROOMS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.FREE_BEVERAGES,
      amenities.PRINTER,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 4 },
      { type: MEETING_ROOM, capacity: 5 },
      { type: MEETING_ROOM, capacity: 5 },
      { type: KITCHEN },
    ],
    lowQuality: true,
    accessInstructions:
      "Use code 9621 to get in the building. Office is on the last floor, first door to the left.",
    wifi: {
      ssid: "CC PROJECTS",
      password: "maps4all",
      speedtest: "https://www.speedtest.net/result/11442626747",
      ping: "8ms",
      download: "53mbps",
      upload: "3mbps",
    },
  },
  {
    title: "Backyard",
    slug: "backyard-sf",
    shortAddress: "SoMa, San Francisco",
    address1: "201 Spear St, Suite 1600",
    address2: "San Francisco, CA 94105",
    cover: "/spaces/backyard-sf/cover.jpeg",
    images: [
      { url: "/spaces/backyard-sf/1.jpeg" },
      { url: "/spaces/backyard-sf/2.jpeg" },
      { url: "/spaces/backyard-sf/3.jpeg" },
      { url: "/spaces/backyard-sf/4.jpeg" },
      { url: "/spaces/backyard-sf/5.jpeg" },
      { url: "/spaces/backyard-sf/6.jpeg" },
      { url: "/spaces/backyard-sf/7.jpeg" },
      { url: "/spaces/backyard-sf/8.jpeg" },
    ],
    coords: [37.79212471954161, -122.39223880136619],
    host: {
      name: "Backyard",
      avatarUrl: "/spaces/backyard-sf/host.png",
      url: "https://backyard.co",
    },
    capacity: 10,
    // rating: 4.6,
    // reviewCount: 9,
    similarLocations: [],
    available: false,
    upcoming: false,
    listed: true,
    area: "sf",
    amenities: [
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.WIFI,
      amenities.MEETING_ROOMS,
      amenities.WHITEBOARD,
      amenities.FREE_BEVERAGES,
    ],
  },
  {
    title: "Financial District",
    slug: "financial-district-ny",
    summary:
      "Light and spacious space near Wall Street. You'll have access to a kitchen, conference rooms and other common areas.",
    shortAddress: "Financial District, New York",
    address1: "26 Broadway, Suite 1106",
    address2: "New York, NY 10004",
    cover: "/spaces/financial-district-ny/cover.jpg",
    images: [
      { url: "/spaces/financial-district-ny/1.jpg" },
      { url: "/spaces/financial-district-ny/2.jpg" },
      { url: "/spaces/financial-district-ny/3.jpg" },
      { url: "/spaces/financial-district-ny/4.jpg" },
      { url: "/spaces/financial-district-ny/5.jpg" },
    ],
    coords: [40.7055871, -74.0137247],
    host: {
      name: "Satchel",
    },
    capacity: 10,
    similarLocations: [],
    available: true,
    upcoming: false,
    listed: false,
    area: "ny",
    accessInstructions: `For entry: Access via key card that you receive on first visit. Take the elevator to the 11th floor. You can get an elevator code from the front desk. Follow the signs to 1106 - turn right from the elevator and walk down the end of the hallway. If you don’t have a keycard you could ring the doorbell.

    For exit: Walk to the third set of elevators, only those elevators will take you down to street level.`,
    amenities: [
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.WIFI,
      amenities.MEETING_ROOMS,
      amenities.WHITEBOARD,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 10 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: KITCHEN },
      { type: LOUNGE_AREA },
    ],
  },
  {
    title: "Humi",
    slug: "humi-toronto",
    summary:
      "Beautiful office space on the Toronto waterfront, with a gorgeous view over the lake.",
    shortAddress: "Toronto Waterfront",
    address1: "207 Queens Quay W #400",
    address2: "Toronto, ON M5J 1A7",
    cover: "/spaces/humi-toronto/cover.jpeg",
    images: Array.from({ length: 5 }).map((_, n) => ({
      url: `/spaces/humi-toronto/${n}.jpeg`,
    })),
    coords: [43.6389557, -79.3826629],
    host: {
      name: "Humi",
      avatarUrl: "/logos/humi.png",
      url: "https://www.humi.ca/",
    },
    capacity: 20,
    similarLocations: [],
    amenities: [
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.WIFI,
      amenities.MEETING_ROOMS,
      amenities.STANDING_DESKS,
      amenities.FREE_BEVERAGES,
    ],
    // accessInstructions:
    //   "Access to the space is provided on-demand via the Kisi app. You'll receive further instructions via email.",
    available: true,
    listed: false,
    area: "toronto",
    upcoming: true,
  },
  {
    title: "Front",
    slug: "front-sf",
    summary:
      "Great open space in the heart of SoMa, with plenty of light and space for your team work from. The space is split into multiple desk clusters to allow enough space between teams. The office is located on a prime area for tech companies, with neighbours such as Pinterest, Miro, and others.",
    shortAddress: "SoMa, San Francisco",
    address1: "525 Brannan Street, unit #308",
    address2: "San Francisco, CA 94107",
    cover: "/spaces/front-sf/cover.jpg",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/front-sf/${n}.jpg`,
    })),
    coords: [37.7775028, -122.3970603],
    host: {
      name: "Front",
      title: "",
      avatarUrl: "/logos/front.png",
      url: "https://frontapp.com/",
    },
    capacity: 15,
    similarLocations: [],
    accessInstructions:
      "The office is located on the 3rd floor, unit #308. On first visit, call Lisette (510-435-1784) when you're downstairs.",
    amenities: [
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.CONFERENCE_ROOMS,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 15 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: KITCHEN },
    ],
    available: true,
    listed: true,
    area: "sf",
    upcoming: false,
    wifi: {
      ssid: "AirDesk@Front",
      password: "iloveairdesk",
    },
    meetingRooms: {
      link: "https://front.skedda.com/register?key=bf096bed9b0c4909&payload=CfDJ8Crhf3oQwftCizhofW-GxUIxgJkEzsJOs46J6xgGW85n9oqfgnyHjqcroo9AfqaMaNbpI4FIK07T-4DNoWMu9j00UtBbU9lvreI5sA3soFWP25dz6svgYig2Licf6Um_--Ggz52HcrjeKMG-snmh4Ps",
    },
  },
  {
    title: "Elements",
    slug: "elements-mission",
    summary:
      "Bright and cozy suite in the Mission district, with a homey feeling, ideal for a couple of small teams to sit together. You'll have access to a small lounge area with a cute fireplace and a fully equipped kitchen, perfect for some team bonding.",
    shortAddress: "Mission District, San Francisco",
    address1: "3178 17th Street",
    address2: "San Francisco, CA 94110",
    cover: "/spaces/elements-mission/cover.png",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/elements-mission/${n}.png`,
    })),
    coords: [37.7637239, -122.4161466],
    host: {
      name: "Elements",
      title: "",
    },
    capacity: 10,
    similarLocations: [],
    accessInstructions: "",
    amenities: [
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.WIFI,
      amenities.CONFERENCE_ROOMS,
      amenities.KITCHEN,
    ],
    available: true,
    listed: false,
    area: "sf",
    upcoming: false,
  },
];

const ycCampListings = [
  {
    title: "Amplemarket (W14)",
    slug: "amplemarket-lisbon",
    summary:
      "Cozy office space in Lisbon with a couple of seats open for YC founders visiting. Happy to take you out for lunch and show you around the city as well!",
    shortAddress: "Entrecampos, Lisbon",
    address1: "Av. Álvaro Pais 14 Escritório 1",
    address2: "1600-873 Lisboa",
    cover: "/spaces/amplemarket-lisbon/cover.jpeg",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/amplemarket-lisbon/${n}.jpeg`,
    })),
    coords: [38.7458052, -9.152906],
    host: {
      name: "Amplemarket",
      batch: "W14",
      avatarUrl: "/logos/amplemarket.png",
      url: "https://amplemarket.com",
    },
    listed: true,
    area: "lisbon",
  },
  {
    title: "Eduflow (S17)",
    slug: "eduflow-copenhagen",
    shortAddress: "København N, Copenhagen",
    address1: "Ewaldsgade 7-9",
    address2: "2200 Copenhagen",
    cover: "/spaces/eduflow-copenhagen/cover.png",
    images: [],
    coords: [55.6827999, 12.5577371],
    host: {
      name: "Eduflow",
      batch: "S17",
      avatarUrl: "/logos/eduflow.png",
      url: "https://www.eduflow.com",
      contact: "david@eduflow.com",
    },
    listed: true,
    area: "copenhagen",
  },
  {
    title: "Fintual (S18)",
    slug: "fintual-santiago",
    shortAddress: "Providencia, Santiago",
    address1: "Av. Providencia 227",
    address2: "Providencia, Santiago Metropolitan Region",
    cover: "/spaces/fintual-santiago/cover.jpg",
    images: Array.from({ length: 5 }).map((_, n) => ({
      url: `/spaces/fintual-santiago/${n}.jpg`,
    })),
    coords: [-33.4362372, -70.6301643],
    host: {
      name: "Fintual",
      batch: "S18",
      avatarUrl: "/logos/fintual.png",
      url: "https://fintual.cl/",
      contact: "pedro@fintual.com",
    },
    listed: true,
    area: "santiago-chile",
  },
  {
    title: "Dondo",
    slug: "dondo-medellin",
    shortAddress: "Barcelona, Medellín",
    address1: "Cl 32D #65f-39",
    address2: "Medellín, Antioquia",
    cover: "/spaces/dondo-medellin/cover.png",
    images: [],
    coords: [6.2373502, -75.5864438],
    host: {
      name: "Dondo",
      avatarUrl: "/logos/dondo.png",
      url: "https://dondo.com/",
      contact: "d@dondo.com",
      contact_phone: "+573113396210",
    },
    listed: true,
    area: "medellin",
  },
  {
    title: "Miso (S16)",
    slug: "miso-seoul",
    shortAddress: "Gangnam, Seoul",
    address1: "396 Seocho-daero, Gangnam Building 18F",
    address2: "Seocho-dong, Seocho-gu, Seoul",
    cover: "/spaces/miso-seoul/cover.png",
    images: [],
    coords: [37.4965219, 127.0246078],
    host: {
      name: "Miso",
      batch: "S16",
      avatarUrl: "/logos/miso.png",
      url: "https://www.getmiso.com/",
      contact: "victor@getmiso.com",
    },
    listed: true,
    area: "seoul",
  },
  {
    title: "Bot MD (S18)",
    slug: "botmd-singapore",
    shortAddress: "Queenstown, Singapore",
    address1: "71 Ayer Rajah Crescent #07-19/20/21",
    address2: "Singapore 139951",
    cover: "/spaces/botmd-singapore/cover.png",
    images: [],
    coords: [1.2966639, 103.7866654],
    host: {
      name: "Bot MD",
      batch: "S18",
      avatarUrl: "/logos/botmd.png",
      url: "https://www.botmd.io/",
      contact: "salina@botmd.io",
    },
    listed: true,
    area: "singapore",
  },
  {
    title: "HyperGlue (W21)",
    slug: "hyperglue-san-mateo",
    shortAddress: "Hayward Park, San Mateo",
    address1: "1825 South Grant St 09-105",
    address2: "San Mateo, CA 94402",
    cover: "/spaces/hyperglue-san-mateo/cover.png",
    images: [],
    coords: [37.5537281, -122.2995879],
    host: {
      name: "HyperGlue",
      batch: "W21",
      // avatarUrl: "/logos/hyperglue.png",
      url: "https://www.hyperglue.com/",
      contact: "sam@hyperglue.com",
    },
    listed: true,
    area: "san-mateo",
  },
  {
    title: "Zenflow (W15)",
    slug: "zenflow-ssf",
    shortAddress: "Oyster Point, South San Francisco",
    address1: "395 Oyster Point Boulevard",
    address2: "South San Francisco, CA 94080",
    cover: "/spaces/zenflow-ssf/cover.jpg",
    images: Array.from({ length: 3 }).map((_, n) => ({
      url: `/spaces/zenflow-ssf/${n}.jpg`,
    })),
    coords: [37.667213, -122.3830221],
    host: {
      name: "Zenflow",
      batch: "W15",
      avatarUrl: "/logos/zenflow.png",
      url: "http://zenflow.com/",
      contact: "nick@zenflow.com",
    },
    listed: true,
    area: "ssf",
  },
  {
    title: "TeamNote (W15)",
    slug: "teamnote-hong-kong",
    shortAddress: "Cheung Sha Wan, Hong Kong",
    address1:
      "Unit B & D, 11/F Gee Hing Chang Industrial Building, 16 Cheung Yue Street",
    address2: "Cheung Sha Wan, Hong Kong",
    cover: "/spaces/teamnote-hong-kong/cover.png",
    images: [],
    coords: [22.3374659, 114.150356],
    host: {
      name: "TeamNote",
      batch: "W15",
      avatarUrl: "/logos/teamnote.png",
      url: "https://teamnoteapp.com/",
      contact: "roy.law@teamnoteapp.com / Toey",
    },
    listed: true,
    area: "hong-kong",
  },
  {
    title: "Canix (S19)",
    slug: "canix-sf",
    shortAddress: "SoMa, San Francisco",
    address1: "63 Bluxome St, Suite A.",
    address2: "San Francisco, CA 94107",
    cover: "/spaces/canix-sf/cover.png",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/canix-sf/${n}.png`,
    })),
    coords: [37.7761459, -122.3971214],
    host: {
      name: "Canix",
      batch: "S19",
      avatarUrl: "/logos/canix.png",
      url: "https://www.canix.com/",
      contact: "stacey@canix.com",
    },
    listed: true,
    area: "sf",
  },
  {
    title: "SuperTokens (S20)",
    slug: "supertokens-maker-mumbai",
    shortAddress: "Nariman Point, Mumbai",
    address1: "920 Maker Chambers 5",
    address2: "Mumbai, Maharashtra 400021",
    cover: "/spaces/supertokens-maker-mumbai/cover.png",
    images: [],
    coords: [18.923807, 72.8222739],
    host: {
      name: "SuperTokens",
      batch: "S20",
      avatarUrl: "/logos/supertokens.png",
      url: "https://supertokens.io/",
      contact: "advait@supertokens.io",
    },
    listed: true,
    area: "mumbai",
  },
  {
    title: "SuperTokens (S20)",
    slug: "supertokens-bkt-mumbai",
    shortAddress: "Lower Parel, Mumbai",
    address1: "BKT House, Lower Parel",
    address2: "Mumbai, Maharashtra 400013",
    cover: "/spaces/supertokens-maker-mumbai/cover.png",
    images: [],
    coords: [19.0028042, 72.8278967],
    host: {
      name: "SuperTokens",
      batch: "S20",
      avatarUrl: "/logos/supertokens.png",
      url: "https://supertokens.io/",
      contact: "advait@supertokens.io",
    },
    listed: true,
    area: "mumbai",
  },
  {
    title: "Apollo Agriculture (F1)",
    slug: "apollo-agriculture-nairobi",
    shortAddress: "Muguga Green, Nairobi",
    address1: "Muguga Green",
    address2: "Nairobi",
    cover: "/spaces/apollo-agriculture-nairobi/cover.jpeg",
    images: [],
    coords: [-1.258994, 36.7888771],
    host: {
      name: "Apollo Agriculture",
      batch: "F1",
      avatarUrl: "/logos/apollo-agriculture.png",
      url: "https://www.apolloagriculture.com/",
      contact: "eli@apolloagriculture.com",
    },
    listed: true,
    area: "nairobi",
  },
  {
    title: "Apollo Agriculture (F1)",
    slug: "apollo-agriculture-amsterdam",
    shortAddress: "Czaar Peterbuurt, Amsterdam",
    address1: "Kraijenhoffstraat 137B",
    address2: "1018 RG Amsterdam",
    cover: "/spaces/apollo-agriculture-nairobi/cover.jpeg",
    images: [],
    coords: [52.3688733, 4.929172],
    host: {
      name: "Apollo Agriculture",
      batch: "F1",
      avatarUrl: "/logos/apollo-agriculture.png",
      url: "https://www.apolloagriculture.com/",
      contact: "eli@apolloagriculture.com",
    },
    listed: false,
    area: "amsterdam",
  },
  {
    title: "HireSweet (W20)",
    slug: "hiresweet-paris",
    shortAddress: "3rd Arrondissement, Paris",
    address1: "3 Impasse de la Planchette",
    address2: "75003 Paris",
    cover: "/spaces/hiresweet-paris/cover.png",
    images: [],
    coords: [48.8681145, 2.3556404],
    host: {
      name: "HireSweet",
      batch: "W20",
      avatarUrl: "/logos/hiresweet.png",
      url: "https://www.hiresweet.com/",
      contact: "robin@hiresweet.com",
    },
    listed: true,
    area: "paris",
  },
  {
    title: "UserGems (S14)",
    slug: "usergems-salzburg",
    shortAddress: "Itzling, Salzburg",
    address1: "Jakob-Haringer-Straße 6",
    address2: "5020 Salzburg",
    cover: "/spaces/usergems-salzburg/cover.png",
    images: [],
    coords: [47.8224092, 13.0424701],
    host: {
      name: "UserGems",
      batch: "S14",
      avatarUrl: "/logos/usergems.png",
      url: "https://www.usergems.com/",
      contact: "stephan@usergems.com",
    },
    listed: true,
    area: "salzburg",
  },
  {
    title: "ClassDojo (IK12)",
    slug: "classdojo-sf",
    shortAddress: "SoMa, San Francisco",
    address1: "735 Tehama Street",
    address2: "San Francisco, CA 94103",
    cover: "/spaces/classdojo-sf/cover.png",
    images: [],
    coords: [37.7752615, -122.4115945],
    host: {
      name: "ClassDojo",
      batch: "IK12",
      avatarUrl: "/logos/classdojo.png",
      url: "https://www.classdojo.com/",
      contact: "sam@classdojo.com",
    },
    listed: true,
    area: "sf",
    upcoming: true,
  },
  {
    title: "ApolloShield (S16)",
    slug: "apollo-shield-tel-aviv",
    shortAddress: "Neve Tzedek, Tel Aviv",
    address1: "Kaufmann St 2",
    address2: "Tel Aviv-Yafo, 6801294",
    cover: "/spaces/apollo-shield-tel-aviv/cover.jpeg",
    images: [],
    coords: [32.057526, 34.7600317],
    host: {
      name: "ApolloShield",
      batch: "S16",
      avatarUrl: "/logos/apollo-shield.png",
      url: "https://www.apolloshield.com/",
    },
    listed: true,
    area: "tel-aviv",
  },
  {
    title: "AXDRAFT (W19)",
    slug: "axdraft-kyiv",
    shortAddress: "Shuliavka (Шулявка), Kyiv",
    address1: "of. 124, Peremohy Ave 26",
    address2: "Kyiv, 04116",
    cover: "/spaces/axdraft-kyiv/cover.png",
    images: [],
    coords: [50.4514123, 30.4682318],
    host: {
      name: "AXDRAFT",
      batch: "W19",
      avatarUrl: "/logos/axdraft.png",
      url: "https://www.axdraft.com/",
    },
    listed: true,
    area: "kyiv",
  },
  {
    title: "MyScoot (W19)",
    slug: "myscoot-new-delhi",
    shortAddress: "Phase IV, Gurugram",
    address1: "Blue 1 Square, Udyog Vihar Phase 4 Rd",
    address2: "Gurugram, Haryana 122016",
    cover: "/spaces/myscoot-new-delhi/cover.png",
    images: [],
    coords: [28.4952849, 77.084732],
    host: {
      name: "MyScoot",
      batch: "W19",
      avatarUrl: "/logos/myscoot.png",
      url: "https://www.myscoot.in",
    },
    listed: true,
    area: "new-delhi",
  },
  {
    title: "Demodesk (W19)",
    slug: "demodesk-munich",
    shortAddress: "Schwabing-Freimann, Munich",
    address1: "Franz-Joseph-Straße 9",
    address2: "80801 München",
    cover: "/spaces/demodesk-munich/cover.png",
    images: [],
    coords: [48.1573279, 11.5822325],
    host: {
      name: "Demodesk",
      batch: "W19",
      avatarUrl: "/logos/demodesk.png",
      url: "https://demodesk.com",
    },
    listed: true,
    area: "munich",
  },
  {
    title: "Impraise (S14)",
    slug: "impraise-amsterdam",
    shortAddress: "Nieuwe Pijp, Amsterdam",
    address1: "Josef Israelskade 46B",
    address2: "1072 SB Amsterdam",
    cover: "/spaces/impraise-amsterdam/cover.jpeg",
    images: [],
    coords: [52.3488268, 4.8916813],
    host: {
      name: "Impraise",
      batch: "S14",
      avatarUrl: "/logos/impraise.png",
      url: "https://www.impraise.com/",
    },
    listed: true,
    area: "amsterdam",
  },
  {
    title: "Legalist (S16)",
    slug: "legalist-sf",
    shortAddress: "San Francisco",
    address1: "San Francisco",
    address2: "San Francisco",
    cover: "/spaces/legalist-sf/cover.jpeg",
    images: [],
    // coords: [52.3488268, 4.8916813],
    approximateCoords: [37.769295639316, -122.42507106525066],
    host: {
      name: "Legalist",
      batch: "S16",
      avatarUrl: "/logos/legalist.png",
      url: "https://www.legalist.com/",
    },
    listed: true,
    area: "sf",
  },
  {
    title: "Lendflow (W21)",
    slug: "lendflow-austin",
    shortAddress: "West Campus, Austin",
    address1: "2222 Rio Grande Street, Building B STE 110",
    address2: "Austin, TX 78705",
    cover: "/spaces/lendflow-austin/cover.png",
    images: [],
    coords: [30.2865492, -97.7454986],
    host: {
      name: "Lendflow",
      batch: "W21",
      avatarUrl: "/logos/lendflow.png",
      url: "https://www.lendflow.io",
    },
    listed: true,
    area: "austin",
  },
  {
    title: "CareRev",
    slug: "carerev-la",
    summary: `Bright open space location.  Walking distance to shops, restaurants and only 10min to the beach! Office has an open concept, that offers multiple desk clusters to allow enough space between teams or can easily be converted to accommodate workflow.

This loft style office space also offers a small lounge area, 2 conference rooms, TV monitors, and 2 modern privacy phone booths. There are also 2 restrooms, a large kitchen that is equipped with a freezer/ refrigerator, microwave and dishwasher. Included access to coffee & espresso or refresh by the water cooler.

The office is located on a prime area Marina Del Rey, Venice and Culver City as known as the Silicon beach! There is also an administrative concierge available to assist in queries.`,
    shortAddress: "Culver - West, LA",
    address1: "13355 West Washington Boulevard",
    address2: "Los Angeles, CA 90066",
    cover: "/spaces/carerev-la/cover.jpeg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/carerev-la/${n}.jpeg`,
    })),
    coords: [33.9925701, -118.4438364],
    host: {
      name: "CareRev",
      batch: "S16",
      avatarUrl: "/logos/carerev.png",
      url: "https://carerev.com",
      about:
        "CareRev is a marketplace technology that connects hospitals and health systems and local, vetted healthcare professionals on demand. We help our customers build a more flexible and resilient workforce by lowering premium labor costs, automating staffing operations, and building a modern-day float pool powered by technology.",
      tags: ["Software", "Marketplace", "Healthcare"],
      joinDate: "2021-06-01",
      hiringUrl: "https://www.carerev.com/careers",
      employeeCount: 250,
      contactPerson: {
        name: "Lisa Wilson",
        title: "Executive Assistant",
        imageUrl: "/spaces/carerev-la/lisa-wilson.jpeg",
      },
    },
    listed: true,
    area: "la",
    amenities: [
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.KITCHEN,
      amenities.CONFERENCE_ROOMS,
      amenities.PHONE_BOOTHS,
      amenities.OFFICE_MANAGER,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 17 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: PHONE_BOOTH, count: 2 },
      { type: KITCHEN },
      { type: RECEPTION },
    ],
    // vibeTags: ["Quiet", "Focused", "Easygoing"],
  },
  {
    title: "Stayflexi (W21)",
    slug: "stayflexi-bengaluru",
    shortAddress: "Vanganahalli, Bengaluru",
    address1: "19th Main Road, Vanganahalli, Sector 3, HSR Layout",
    address2: "Bengaluru, Karnataka 560102",
    cover: "/spaces/stayflexi-bengaluru/cover.png",
    images: [],
    coords: [12.9085679, 77.6440444],
    host: {
      name: "Stayflexi",
      batch: "W21",
      avatarUrl: "/logos/stayflexi.png",
      url: "https://business.stayflexi.com/",
    },
    listed: true,
    area: "bengaluru",
  },
  {
    title: "Meitre (W18)",
    slug: "meitre-palo-alto",
    shortAddress: "Downtown North, Palo Alto",
    address1: "530 Lytton Avenue",
    address2: "Palo Alto, CA 94301",
    cover: "/spaces/meitre-palo-alto/cover.png",
    images: [],
    coords: [37.4493401, -122.1599696],
    host: {
      name: "Meitre",
      batch: "W18",
      avatarUrl: "/logos/meitre.png",
      url: "https://meitre.com",
    },
    listed: true,
    area: "palo-alto",
  },
  {
    title: "Darrow (W21)",
    slug: "darrow-tel-aviv",
    shortAddress: "Sarona, Tel Aviv",
    address1: "HaArba'a St 30, Hagag Towers, Southern Tower, 12th floor",
    address2: "Tel Aviv",
    cover: "/spaces/darrow-tel-aviv/cover.png",
    images: [],
    coords: [32.0698592, 34.7866693],
    host: {
      name: "Darrow",
      batch: "W21",
      avatarUrl: "/logos/darrow.png",
      url: "https://www.darrow.ai",
    },
    listed: true,
    area: "tel-aviv",
  },
  {
    title: "Aspire (W18)",
    slug: "aspire-singapore",
    shortAddress: "Rochor, Singapore",
    address1: "1 Syed Alwi Road, Song Lin Building, #04-04",
    address2: "Singapore 207628",
    cover: "/spaces/aspire/cover.png",
    images: [],
    coords: [1.3061247, 103.8587661],
    host: {
      name: "Aspire",
      batch: "W18",
      avatarUrl: "/logos/aspire.png",
      url: "https://aspireapp.com/",
    },
    listed: true,
    area: "singapore",
  },
  {
    title: "Aspire (W18)",
    slug: "aspire-bangkok",
    shortAddress: "Khlong Tan Nuea, Bangkok",
    address1: "Sukhumvit soi 49, Sukhumvit Road, Klongton Nua",
    address2: "Bangkok, Thailand 10110",
    cover: "/spaces/aspire/cover.png",
    images: [],
    coords: [13.7320753, 100.5756965],
    host: {
      name: "Aspire",
      batch: "W18",
      avatarUrl: "/logos/aspire.png",
      url: "https://aspireapp.com/",
    },
    listed: false,
    area: "bangkok",
  },
  {
    title: "Aspire (W18)",
    slug: "aspire-ho-chi-minh",
    // original LI
    // 22 Dien Bien Phu Street, Vo Thi Sau Ward, District 3, Circo Building, District 3, Ho Chi Minh City VN
    shortAddress: "Đa Kao, Ho Chi Minh",
    address1: "22 Điện Biên Phủ, Đa Kao, Quận 1",
    address2: "Bangkok, Thailand 10110",
    cover: "/spaces/aspire/cover.png",
    images: [],
    coords: [10.7908904, 106.7005487],
    host: {
      name: "Aspire",
      batch: "W18",
      avatarUrl: "/logos/aspire.png",
      url: "https://aspireapp.com/",
    },
    listed: false,
    area: "ho-chi-minh",
  },
  {
    title: "Aspire (W18)",
    slug: "aspire-jakarta",
    shortAddress: "East Kuningan, Jakarta",
    address1: "Jl. Prof. Dr. Satrio Kav. E-IV, RDTX Tower Lantai 11 Unit 1106",
    address2: "Jakarta 12950",
    cover: "/spaces/aspire/cover.png",
    images: [],
    coords: [-6.2251808, 106.8251859],
    host: {
      name: "Aspire",
      batch: "W18",
      avatarUrl: "/logos/aspire.png",
      url: "https://aspireapp.com/",
    },
    listed: false,
    area: "jakarta",
  },
  {
    title: "GetAccept (W16)",
    slug: "getaccept-malmo",
    shortAddress: "Gamla staden, Malmö",
    address1: "Södergatan 13",
    address2: "211 34 Malmö",
    cover: "/spaces/getaccept/cover.png",
    images: [],
    coords: [55.6041392, 13.0009273],
    host: {
      name: "GetAccept",
      batch: "W16",
      avatarUrl: "/logos/getaccept.png",
      url: "https://www.getaccept.com",
    },
    listed: true,
    area: "malmo",
  },
  {
    title: "GetAccept (W16)",
    slug: "getaccept-stockholm",
    shortAddress: "Kungsholmen, Stockholm",
    address1: "Kungsbroplan 1",
    address2: "112 26 Stockholm",
    cover: "/spaces/getaccept/cover.png",
    images: [],
    coords: [59.3317485, 18.0491811],
    host: {
      name: "GetAccept",
      batch: "W16",
      avatarUrl: "/logos/getaccept.png",
      url: "https://www.getaccept.com",
    },
    listed: true,
    area: "stockholm",
  },
  {
    title: "GetAccept (W16)",
    slug: "getaccept-aarhus",
    shortAddress: "Frederiksbjerg, Aarhus",
    address1: "Jægergårdsgade 97, 3",
    address2: "8000 Aarhus",
    cover: "/spaces/getaccept/cover.png",
    images: [],
    coords: [56.148617, 10.2053334],
    host: {
      name: "GetAccept",
      batch: "W16",
      avatarUrl: "/logos/getaccept.png",
      url: "https://www.getaccept.com",
    },
    listed: true,
    area: "aarhus",
  },
  {
    title: "GetAccept (W16)",
    slug: "getaccept-oslo",
    shortAddress: "Sentrum, Oslo",
    address1: "Akersgata 16",
    address2: "0158 Oslo",
    cover: "/spaces/getaccept/cover.png",
    images: [],
    coords: [59.911895, 10.740308],
    host: {
      name: "GetAccept",
      batch: "W16",
      avatarUrl: "/logos/getaccept.png",
      url: "https://www.getaccept.com",
    },
    listed: true,
    area: "oslo",
  },
  {
    title: "GetAccept (W16)",
    slug: "getaccept-marseille",
    shortAddress: "Palais-de-Justice, Marseille",
    address1: "19 Rue Roux de Brignoles",
    address2: "13006 Marseille",
    cover: "/spaces/getaccept/cover.png",
    images: [],
    coords: [43.2893856, 5.3741658],
    host: {
      name: "GetAccept",
      batch: "W16",
      avatarUrl: "/logos/getaccept.png",
      url: "https://www.getaccept.com",
    },
    listed: true,
    area: "marseille",
  },
  {
    title: "Taktile (S20)",
    slug: "taktile-berlin",
    shortAddress: "Mitte, Berlin",
    address1: "Chausseestraße 49",
    address2: "10115 Berlin",
    cover: "/spaces/taktile-berlin/cover.png",
    images: [],
    coords: [52.535197, 13.3778003],
    host: {
      name: "Taktile",
      batch: "S20",
      avatarUrl: "/logos/taktile.png",
      url: "https://www.taktile.com/",
    },
    listed: true,
    area: "berlin",
  },
  {
    title: "Courier (S19)",
    slug: "courier-sf",
    shortAddress: "SoMa, San Francisco",
    address1: "182 Shipley",
    address2: "San Francisco, CA 94107",
    cover: "/spaces/courier-sf/cover.png",
    images: [],
    coords: [37.780118, -122.4025782],
    host: {
      name: "Courier",
      batch: "S19",
      avatarUrl: "/logos/courier.png",
      url: "https://www.courier.com/",
    },
    listed: true,
    area: "sf",
  },
  {
    title: "Dayra (W21)",
    slug: "dayra-cairo",
    shortAddress: "Zamalek, Cairo",
    address1: "157 26th of July Corridor",
    address2: "Mohammed Mazhar, Zamalek",
    cover: "/spaces/dayra-cairo/cover.png",
    images: [],
    coords: [30.0586432, 31.2243272],
    host: {
      name: "Dayra",
      batch: "W21",
      avatarUrl: "/logos/dayra.png",
      url: "https://www.dayra.co/",
    },
    listed: true,
    area: "cairo",
  },
  {
    title: "AesculaTech (W18)",
    slug: "aescula-tech-la",
    shortAddress: "City West, Los Angeles",
    address1: "1010 Wilshire Boulevard",
    address2: "Los Angeles, CA 90017",
    cover: "/spaces/aescula-tech-la/cover.png",
    images: [],
    coords: [34.0512979, -118.2631238],
    host: {
      name: "AesculaTech",
      batch: "W18",
      avatarUrl: "/logos/aescula-tech.png",
      url: "https://www.aesculatech.com",
    },
    listed: true,
    area: "la",
  },
  {
    title: "Trella (S19)",
    slug: "trella-cairo",
    shortAddress: "Cairo",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/trella-cairo/cover.jpeg",
    images: [],
    // coords: [30.0594885, 31.2584644],
    approximateCoords: [30.048041293486044, 31.23580543510078],
    host: {
      name: "Trella",
      batch: "S19",
      avatarUrl: "/logos/trella.png",
      url: "https://www.trella.app/",
    },
    listed: true,
    area: "cairo",
  },
  {
    title: "Sixfold Bioscience (W18)",
    slug: "sixfold-bioscience-london",
    shortAddress: "Hammersmith and Fulham, London",
    address1: "80 Wood Ln",
    address2: "London W12 0BZ",
    cover: "/spaces/sixfold-bioscience-london/cover.png",
    images: [],
    coords: [51.515988, -0.2246061],
    host: {
      name: "Sixfold",
      batch: "W18",
      avatarUrl: "/logos/sixfold-bioscience.png",
      url: "https://www.sixfold.bio/",
    },
    listed: true,
    area: "london",
  },
  {
    title: "Pop Meals (S17)",
    slug: "pop-meals-kuala-lumpur",
    shortAddress: "Bangsar South, Kuala Lumpur",
    address1:
      "Level 28, Vertical Corporate Tower B No. 8, Avenue 10, Bangsar South, Jalan Kerinchi",
    address2: "59200 Kuala Lumpur",
    cover: "/spaces/pop-meals-kuala-lumpur/cover.png",
    images: [],
    coords: [3.1111071, 101.6661125],
    host: {
      name: "Pop Meals",
      batch: "S17",
      avatarUrl: "/logos/pop-meals.png",
      url: "https://popmeals.com.my/",
    },
    listed: true,
    area: "kuala-lumpur",
  },
  {
    title: "Short Story (S19)",
    slug: "short-story-sf",
    shortAddress: "Silver Terrace, San Francisco",
    address1: "1833 Egbert Avenue",
    address2: "San Francisco, CA 94124",
    cover: "/spaces/short-story-sf/cover.png",
    images: [],
    coords: [37.7270244, -122.4009325],
    host: {
      name: "Short Story",
      batch: "S19",
      avatarUrl: "/logos/short-story.png",
      url: "https://shortstorybox.com/",
    },
    listed: true,
    area: "sf",
  },
  {
    title: "Spire Law (W19)",
    slug: "spire-law-orlando",
    shortAddress: "Oviedo, FL",
    address1: "2572 W State Rd 426",
    address2: "Oviedo, FL 32765",
    cover: "/spaces/spire-law-orlando/cover.png",
    images: [],
    coords: [28.6328467, -81.2398254],
    host: {
      name: "Spire Law",
      batch: "W19",
      avatarUrl: "/logos/spire-law.png",
      url: "https://spirelawfirm.com/",
    },
    listed: true,
    area: "orlando",
  },
  {
    title: "Hypotenuse (S20)",
    slug: "hypotenuse-singapore",
    shortAddress: "Queenstown, Singapore",
    address1: "71 Ayer Rajah Crescent, #05-07",
    address2: "Singapore 139951",
    cover: "/spaces/hypotenuse-singapore/cover.png",
    images: [],
    coords: [1.2967926, 103.786762],
    host: {
      name: "Hypotenuse",
      batch: "S20",
      avatarUrl: "/logos/hypotenuse.png",
      url: "https://hypotenuse.ai/",
    },
    listed: true,
    area: "singapore",
  },
  {
    title: "DeepSource (W20)",
    slug: "deepsource-bengaluru",
    // 2nd floor, 2287, 14th Main Rd, HAL 2nd Stage, Indiranagar, Bengaluru, Karnataka 560008, India ??
    shortAddress: "Bengaluru",
    address1: "unknown",
    address2: "unknwon",
    cover: "/spaces/deepsource-bengaluru/cover.png",
    images: [],
    // coords: [28.6328467, -81.2398254],
    approximateCoords: [12.979387175984442, 77.59041695663927],
    host: {
      name: "DeepSource",
      batch: "W20",
      avatarUrl: "/logos/deepsource.png",
      url: "https://deepsource.io/",
    },
    listed: true,
    area: "bengaluru",
  },
  {
    title: "Bottomless (W19)",
    slug: "bottomless-seattle",
    shortAddress: "Downtown, Seattle",
    address1: "1402 3rd Avenue",
    address2: "Seattle, WA 98101",
    cover: "/spaces/bottomless-seattle/cover.png",
    images: [],
    coords: [47.6090571, -122.3366291],
    host: {
      name: "Bottomless",
      batch: "W19",
      avatarUrl: "/logos/bottomless.png",
      url: "https://www.bottomless.com/",
    },
    listed: true,
    area: "seattle",
  },
  {
    title: "AllSome Fulfillment (W19)",
    slug: "allsome-fulfillment-shenzhen",
    shortAddress: "Shenzhen",
    address1: "Block 2, GuangMing YunLi Smart Park, 143, XiHuan DaDao",
    address2: "518106 ShenZhen",
    cover: "/spaces/allsome-fulfillment-shenzhen/cover.JPG",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/allsome-fulfillment-shenzhen/${n}.JPG`,
    })),
    coords: [22.77476306163247, 113.87066268458284],
    host: {
      name: "AllSome Fulfillment",
      batch: "W19",
      avatarUrl: "/logos/allsome-fulfillment.png",
      url: "https://www.allsome.my/",
    },
    listed: true,
    area: "shenzhen",
  },
  {
    title: "AllSome Fulfillment (W19)",
    slug: "allsome-fulfillment-selangor",
    shortAddress: "Kawasan Miel, Shah Alam",
    address1: "Block 9, Hap Seng Business Park, No 12, Persiaran Perusahaan",
    address2: "Kawasan Miel, 40300 Shah Alam",
    cover: "/spaces/allsome-fulfillment-selangor/cover.png",
    images: [],
    coords: [3.0507416, 101.5418893],
    host: {
      name: "AllSome Fulfillment",
      batch: "W19",
      avatarUrl: "/logos/allsome-fulfillment.png",
      url: "https://www.allsome.my/",
    },
    listed: true,
    area: "selangor",
  },
  {
    title: "Thndr (S20)",
    slug: "thndr-cairo",
    shortAddress: "Al Maadi, Cairo",
    address1: "37 Street 231",
    address2: "Maadi as Sarayat Al Gharbeyah, Cairo",
    cover: "/spaces/thndr-cairo/cover.png",
    images: [],
    coords: [29.9630941, 31.2772443],
    host: {
      name: "Thndr",
      batch: "S20",
      avatarUrl: "/logos/thndr.png",
      url: "https://www.thndr.app",
    },
    listed: true,
    area: "cairo",
  },
  {
    title: "Kunduz (S18)",
    slug: "kunduz-istanbul",
    summary:
      "The neighborhood is called Akaretler — pretty picturesque, at the heart of the city and near Bosphorus. There are tons of bars, cafes & restaurants - some of which offer the best of their variety in the city (from best doner to best cheesecake) - all nearby.",
    shortAddress: "Akaretler, Istanbul",
    address1: "Süleyman Seba Cd. No:6",
    address2: "34353 Beşiktaş/İstanbul",
    cover: "/spaces/kunduz-istanbul/cover.png",
    images: [],
    coords: [41.0418799, 29.0032853],
    host: {
      name: "Kunduz",
      batch: "S18",
      avatarUrl: "/logos/kunduz.png",
      url: "https://kunduz.com/",
    },
    listed: true,
    area: "istanbul",
  },
  {
    title: "Atomized (S20)",
    slug: "atomized-charlotte",
    shortAddress: "Second Ward, Charlotte",
    address1: "615 S College Street",
    address2: "Charlotte, NC 28202",
    cover: "/spaces/atomized-charlotte/cover.png",
    images: [],
    coords: [35.2213926, -80.8478154],
    host: {
      name: "Atomized",
      batch: "S20",
      avatarUrl: "/logos/atomized.png",
      url: "https://atomizedhq.com/",
    },
    listed: true,
    area: "charlotte",
  },
  {
    title: "Eden Farm (S19)",
    slug: "eden-farm-jakarta",
    shortAddress: "South Kembangan, Jakarta",
    address1: "Lippo St. Moritz Office Tower, 9th Fl. Unit 0902",
    address2: "Jakarta 11610",
    cover: "/spaces/eden-farm/cover.png",
    images: [],
    coords: [-6.1885341, 106.738717],
    host: {
      name: "Eden Farm",
      batch: "S19",
      avatarUrl: "/logos/eden-farm.png",
      url: "https://www.edenfarm.id/",
    },
    listed: true,
    area: "jakarta",
  },
  {
    title: "Eden Farm (S19)",
    slug: "eden-farm-bandung",
    shortAddress: "Malabar, Bandung",
    address1: "Greko Creative Hub, Jl. Jendral Ahmad Yani No.134-136",
    address2: "Jawa Barat 40262",
    cover: "/spaces/eden-farm/cover.png",
    images: [],
    coords: [-6.9213026, 107.6219589],
    host: {
      name: "Eden Farm",
      batch: "S19",
      avatarUrl: "/logos/eden-farm.png",
      url: "https://www.edenfarm.id/",
    },
    listed: true,
    area: "bandung",
  },
  {
    title: "Eden Farm (S19)",
    slug: "eden-farm-semarang",
    shortAddress: "West Semarang",
    address1:
      "Jl. Yos Sudarso No.8, Tawangsari, Kec. Semarang Bar., Kota Semarang",
    address2: "Jawa Tengah 50144",
    cover: "/spaces/eden-farm/cover.png",
    images: [],
    coords: [-6.9533184, 110.4063347],
    host: {
      name: "Eden Farm",
      batch: "S19",
      avatarUrl: "/logos/eden-farm.png",
      url: "https://www.edenfarm.id/",
    },
    listed: true,
    area: "semarang",
  },
  {
    title: "Eden Farm (S19)",
    slug: "eden-farm-sidoarjo",
    shortAddress: "Gedangan, Sidoarjo Regency",
    address1:
      "Pergudangan Gedangan Permai blok A 15 -16, Megersari, Gedangan, Sidoarjo Regency",
    address2: "Jawa Timur 61254",
    cover: "/spaces/eden-farm/cover.png",
    images: [],
    coords: [-7.3935547, 112.7298785],
    host: {
      name: "Eden Farm",
      batch: "S19",
      avatarUrl: "/logos/eden-farm.png",
      url: "https://www.edenfarm.id/",
    },
    listed: true,
    area: "sidoarjo",
  },
  {
    title: "Sidekick (S20)",
    slug: "sidekick-nyc",
    shortAddress: "Midtown Manhattan, New York",
    address1: "167 Madison Ave",
    address2: "New York, NY 10016",
    cover: "/spaces/sidekick-nyc/cover.png",
    images: [],
    coords: [40.7467854, -73.9833069],
    host: {
      name: "Sidekick",
      batch: "S20",
      avatarUrl: "/logos/sidekick.png",
      url: "https://sidekick.video/",
    },
    listed: true,
    area: "ny",
  },
  {
    title: "Just Appraised (S17)",
    slug: "just-appraised-palo-alto",
    shortAddress: "Palo Alto",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/just-appraised-palo-alto/cover.png",
    images: [],
    // coords: [-7.3935547, 112.7298785],
    approximateCoords: [37.44418255222417, -122.14256030370098],
    host: {
      name: "Just Appraised",
      batch: "S17",
      avatarUrl: "/logos/just-appraised.png",
      url: "https://www.justappraised.com/",
    },
    listed: true,
    area: "palo-alto",
  },
  {
    title: "PowerUs (S20)",
    slug: "power-us-berlin",
    shortAddress: "Mitte, Berlin",
    address1: "Torstraße 178",
    address2: "10115 Berlin",
    cover: "/spaces/power-us-berlin/cover.png",
    images: [],
    coords: [52.5285081, 13.3945588],
    host: {
      name: "PowerUs",
      batch: "S20",
      avatarUrl: "/logos/power-us.png",
      url: "https://powerus.de/",
    },
    listed: true,
    area: "berlin",
  },
  {
    title: "Apify (F1)",
    slug: "apify-prague",
    shortAddress: "Prague",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/apify-prague/cover.png",
    images: [],
    // coords: [52.5285081, 13.3945588],
    approximateCoords: [50.07545225386254, 14.434031502947521],
    host: {
      name: "Apify",
      batch: "F1",
      avatarUrl: "/logos/apify.png",
      url: "https://apify.com/",
    },
    listed: true,
    area: "prague",
  },
  {
    title: "Pearmill (F2)",
    slug: "pearmill-nyc",
    shortAddress: "New York City",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/pearmill-nyc/cover.png",
    images: [],
    // coords: [52.5285081, 13.3945588],
    approximateCoords: [40.7100509, -74.0115391],
    host: {
      name: "Pearmill",
      batch: "F2",
      avatarUrl: "/logos/pearmill.png",
      url: "https://pearmill.com/",
    },
    listed: true,
    area: "ny",
  },
  {
    title: "Turing Labs (W20)",
    slug: "turing-labs-nyc",
    shortAddress: "Midtown Manhattan, New York",
    address1: "31 West 34th Street, 8th Floor",
    address2: "New York, NY 10001",
    cover: "/spaces/turing-labs-nyc/cover.png",
    images: [],
    coords: [40.7493834, -73.9860146],
    host: {
      name: "Turing Labs",
      batch: "W20",
      avatarUrl: "/logos/turing-labs.png",
      url: "https://www.turingsaas.com/",
    },
    listed: true,
    area: "ny",
  },
  {
    title: "Flat (S20)",
    slug: "flat-cdmx",
    shortAddress: "Polanco, Mexico City",
    address1: "Av. Pdte. Masaryk 191, Polanco",
    address2: "11560 Ciudad de México",
    cover: "/spaces/flat-cdmx/cover.png",
    images: [],
    coords: [19.4314712, -99.189389],
    host: {
      name: "Flat",
      batch: "S20",
      avatarUrl: "/logos/flat.png",
      url: "https://flat.mx/",
    },
    listed: true,
    area: "cdmx",
  },
  {
    title: "Riot Security (W20)",
    slug: "riot-paris",
    shortAddress: "Le Marais, Paris",
    address1: "6 Rue Beaubourg",
    address2: "75004 Paris",
    cover: "/spaces/riot-paris/cover.png",
    images: [],
    coords: [48.8602629, 2.3534878],
    host: {
      name: "Riot Security",
      batch: "W20",
      avatarUrl: "/logos/riot.png",
      url: "https://tryriot.com/",
    },
    listed: true,
    area: "paris",
  },
  {
    title: "Once (S20)",
    slug: "once-paris",
    shortAddress: "Paris",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/once-paris/cover.png",
    images: [],
    // coords: [48.8602629, 2.3534878],
    approximateCoords: [48.8588377, 2.2770203],
    host: {
      name: "Once",
      batch: "S20",
      avatarUrl: "/logos/once.png",
      url: "https://www.once.app/",
    },
    listed: true,
    area: "paris",
  },
  {
    title: "Tienda Dólar (W20)",
    slug: "tienda-dolar-buenos-aires",
    shortAddress: "Buenos Aires",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/tienda-dolar-buenos-aires/cover.png",
    images: [],
    // coords: [48.8602629, 2.3534878],
    approximateCoords: [-34.5962226, -58.3891855],
    host: {
      name: "Tienda Dólar",
      batch: "W20",
      avatarUrl: "/logos/tienda-dolar.png",
      url: "https://tiendadolar.com.ar/",
    },
    listed: true,
    area: "buenos-aires",
  },
  {
    title: "Blair (S19)",
    slug: "blair-berlin",
    shortAddress: "Berlin",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/blair-berlin/cover.png",
    images: [],
    // coords: [48.8602629, 2.3534878],
    approximateCoords: [52.5065133, 13.1445555],
    host: {
      name: "Blair",
      batch: "S19",
      avatarUrl: "/logos/blair.png",
      url: "https://joinblair.com/",
    },
    listed: true,
    area: "berlin",
  },
  {
    title: "Handl (W20)",
    slug: "handl-sf",
    shortAddress: "San Francisco",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/handl-sf/cover.png",
    images: [],
    // coords: [48.8602629, 2.3534878],
    approximateCoords: [37.7599043, -122.4256016],
    host: {
      name: "Handl",
      batch: "W20",
      avatarUrl: "/logos/handl.png",
      url: "https://handl.ai/",
    },
    listed: true,
    area: "sf",
  },
  {
    title: "Breadfast (S19)",
    slug: "breadfast-cairo",
    shortAddress: "Cairo",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/breadfast-cairo/cover.png",
    images: [],
    // coords: [48.8602629, 2.3534878],
    approximateCoords: [30.044328111862022, 31.240439257612945],
    host: {
      name: "Breadfast",
      batch: "S19",
      avatarUrl: "/logos/breadfast.png",
      url: "https://www.breadfast.com/",
    },
    listed: true,
    area: "cairo",
  },
  {
    title: "Abacum (W21)",
    slug: "abacum-barcelona",
    shortAddress: "Barcelona",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/abacum-barcelona/cover.png",
    images: [],
    // coords: [48.8602629, 2.3534878],
    approximateCoords: [41.3919356966771, 2.1824111930032095],
    host: {
      name: "Abacum",
      batch: "W21",
      avatarUrl: "/logos/abacum.png",
      url: "https://www.abacum.io/",
    },
    listed: true,
    area: "barcelona",
  },
  {
    title: "iSono Health (W16)",
    slug: "isono-health-ssf",
    shortAddress: "Oyster Point, South San Francisco",
    address1: "395 Oyster Point Boulevard",
    address2: "South San Francisco, CA 94080",
    cover: "/spaces/isono-health-ssf/cover.png",
    images: [],
    coords: [37.667213, -122.3830221],
    host: {
      name: "iSono Health",
      batch: "W16",
      avatarUrl: "/logos/isono-health.png",
      url: "https://isonohealth.com/",
    },
    listed: true,
    area: "ssf",
  },
  {
    title: "Endpoint Health (W19)",
    slug: "endpoint-health-palo-alto",
    shortAddress: "Ventura, Palo Alto",
    address1: "401 Lambert Ave",
    address2: "Palo Alto, CA 94306",
    cover: "/spaces/endpoint-health-palo-alto/cover.png",
    images: [],
    coords: [37.4216727, -122.1360199],
    host: {
      name: "Endpoint Health",
      batch: "W19",
      avatarUrl: "/logos/endpoint-health.png",
      url: "https://endpoint.health/",
    },
    listed: true,
    area: "palo alto",
  },
  // {
  //   title: "Setly",
  //   slug: "setly-stockholm",
  //   shortAddress: "Stockholm",
  //   address1: "...",
  //   address2: "...",
  //   cover: "/spaces/settly-stockholm/cover.png",
  //   images: [],
  //   // coords: [37.4216727, -122.1360199],
  //   host: {
  //     name: "Setly",
  //     avatarUrl: "/logos/settly.png",
  //     url: "https://setly.com/",
  //   },
  //   listed: true,
  //   area: "stockholm",
  //   wifi: {
  //     ssid: "Setly Guest",
  //     password: "Redovisning!",
  //     speedtest: "https://www.speedtest.net/result/11467392433",
  //     ping: "10ms",
  //     download: "140mbps",
  //     upload: "185mbps",
  //   },
  // },
  {
    title: "Redcliffe Lifetech (W21)",
    slug: "redcliffe-lifetech-new-delhi",
    shortAddress: "Kishan Ganj, New Delhi",
    address1: "P3-105, Central Square, Bara Hindu Rao",
    address2: "Delhi - 110006",
    cover: "/spaces/redcliffe-lifetech-new-delhi/cover.png",
    images: [],
    coords: [28.6635087, 77.2030118],
    host: {
      name: "Redcliffe Lifetech",
      batch: "W21",
      avatarUrl: "/logos/redcliffe-lifetech.png",
      url: "https://redcliffelife.com/",
    },
    listed: true,
    area: "new-delhi",
  },
  {
    title: "Redcliffe Lifetech (W21)",
    slug: "redcliffe-lifetech-noida",
    shortAddress: "Electronic City, Noida",
    address1: "H55, 3rd Floor, Sector 63, Electronic City, Noida",
    address2: "Uttar Pradesh - 201301",
    cover: "/spaces/redcliffe-lifetech-noida/cover.png",
    images: [],
    coords: [28.627602, 77.3750266],
    host: {
      name: "Redcliffe Lifetech",
      batch: "W21",
      avatarUrl: "/logos/redcliffe-lifetech.png",
      url: "https://redcliffelife.com/",
    },
    listed: true,
    area: "noida",
  },
  {
    title: "Truora (W19)",
    slug: "truora-bogota",
    shortAddress: "El Chicó, Bogotá",
    address1: "Cra. 12 ##90-20",
    address2: "Bogotá",
    cover: "/spaces/truora/cover.png",
    images: [],
    coords: [4.6728299, -74.0500189],
    host: {
      name: "Truora",
      batch: "W19",
      avatarUrl: "/logos/truora.png",
      url: "https://www.truora.com/",
    },
    listed: true,
    area: "bogota",
  },
  {
    title: "Truora (W19)",
    slug: "truora-cali",
    shortAddress: "El Ingenio, Cali",
    address1: "Cra. 84a ###14-115",
    address2: "Cali, Valle del Cauca",
    cover: "/spaces/truora/cover.png",
    images: [],
    coords: [3.3831568, -76.5319699],
    host: {
      name: "Truora",
      batch: "W19",
      avatarUrl: "/logos/truora.png",
      url: "https://www.truora.com/",
    },
    listed: true,
    area: "cali",
  },
  {
    title: "Modernbanc (W20)",
    slug: "modernbanc-london",
    shortAddress: "Canary Wharf, London",
    address1: "25 Cabot Square",
    address2: "E14 4QZ, London",
    cover: "/spaces/modernbanc-london/cover.png",
    images: [],
    coords: [51.5046878, -0.0232754],
    host: {
      name: "Modernbanc",
      batch: "W20",
      avatarUrl: "/logos/modernbanc.png",
      url: "https://www.modernbanc.com/",
    },
    listed: true,
    area: "london",
  },
  {
    title: "RingMD (S20)",
    slug: "ringmd-boston",
    shortAddress: "Bay Village, Boston",
    address1: "39 Melrose St #2",
    address2: "Boston, MA 02116",
    cover: "/spaces/ringmd-boston/cover.png",
    images: [],
    coords: [42.3487715, -71.0686185],
    host: {
      name: "RingMD",
      batch: "S20",
      avatarUrl: "/logos/ringmd.png",
      url: "https://www.ring.md/",
    },
    listed: true,
    area: "boston",
  },
  {
    title: "Moonshot Brands (W21)",
    slug: "moonshot-brands-nosara",
    shortAddress: "Playa Guiones, Nosara",
    address1: "Casa Costa Rica, 200m south Hotel Harbor Reef",
    address2: "Nosara, 50206",
    cover: "/spaces/moonshot-brands-nosara/cover.png",
    images: [],
    coords: [9.9390898, -85.6644903],
    host: {
      name: "Moonshot Brands",
      batch: "W21",
      avatarUrl: "/logos/moonshot-brands.png",
      url: "https://www.moonshotbrands.com/",
    },
    listed: true,
    area: "nosara",
  },
  {
    title: "Minimum (S20)",
    slug: "minimum-london",
    shortAddress: "London",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/minimum-london/cover.png",
    images: [],
    // coords: [9.9390898, -85.6644903],
    approximateCoords: [51.50804903394043, -0.12769766154284728],
    host: {
      name: "Minimum",
      batch: "S20",
      avatarUrl: "/logos/minimum.png",
      url: "https://www.minimum.eco/",
    },
    listed: true,
    area: "london",
  },
  {
    title: "Spruce (W21)",
    slug: "spruce-nyc",
    shortAddress: "New York",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/spruce-nyc/cover.png",
    images: [],
    // coords: [9.9390898, -85.6644903],
    approximateCoords: [40.699231756984126, -74.08682957297505],
    host: {
      name: "Spruce",
      batch: "W21",
      avatarUrl: "/logos/spruce.png",
      url: "https://www.spruceid.com/",
    },
    listed: true,
    area: "ny",
  },
  {
    title: "Jemi (S20)",
    slug: "jemi-sf",
    shortAddress: "Cole Valley, San Francisco",
    address1: "743 Clayton Street",
    address2: "San Francisco, CA 94117",
    cover: "/spaces/jemi-sf/cover.png",
    images: [],
    coords: [37.7677624, -122.4486945],
    host: {
      name: "Jemi",
      batch: "S20",
      avatarUrl: "/logos/jemi.png",
      url: "https://jemi.app/",
    },
    listed: true,
    area: "sf",
  },
  {
    title: "TRACTIAN (W21)",
    slug: "tractian-sao-paulo",
    shortAddress: "Vila Mariana, São Paulo",
    address1: "R. Azevedo Macedo, 20",
    address2: "São Paulo - SP, 04013-060",
    cover: "/spaces/tractian-sao-paulo/cover.png",
    images: [],
    coords: [-23.5807148, -46.6400725],
    host: {
      name: "TRACTIAN",
      batch: "W21",
      avatarUrl: "/logos/tractian.png",
      url: "https://tractian.com/",
    },
    listed: true,
    area: "sao-paulo",
  },
  {
    title: "inBalance (W21)",
    slug: "inbalance-boston",
    shortAddress: "Boston",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/inbalance-boston/cover.png",
    images: [],
    // coords: [-23.5807148, -46.6400725],
    approximateCoords: [42.358861371390866, -71.06067255597718],
    host: {
      name: "inBalance",
      batch: "W21",
      avatarUrl: "/logos/inbalance.png",
      url: "https://inbalanceresearch.com/",
    },
    listed: true,
    area: "boston",
  },
  {
    title: "Bloom (W20)",
    slug: "bloom-oakland",
    shortAddress: "Downtown, Oakland",
    address1: "1111 Broadway",
    address2: "Oakland, CA 94607",
    cover: "/spaces/bloom-oakland/cover.png",
    images: [],
    coords: [37.8024721, -122.2729854],
    host: {
      name: "Bloom",
      batch: "W20",
      avatarUrl: "/logos/bloom.png",
      url: "https://www.joinbloom.community/",
    },
    listed: true,
    area: "oakland",
  },
  {
    title: "Ramani (W20)",
    slug: "ramani-dar-salaam",
    shortAddress: "Mikocheni, Dar es Salaam",
    address1: "Isale House, Mikocheni",
    address2: "Dar es salaam",
    cover: "/spaces/ramani-dar-salaam/cover.png",
    images: [],
    coords: [-6.758814569471234, 39.25072363268583],
    host: {
      name: "Ramani",
      batch: "W20",
      avatarUrl: "/logos/ramani.png",
      url: "https://www.ramani.io/",
    },
    listed: true,
    area: "dar-salaam",
  },
  {
    title: "Withfriends (W19)",
    slug: "withfriends-brooklyn",
    shortAddress: "Bushwick, Brooklyn",
    address1: "1449 Broadway",
    address2: "Brooklyn, NY 11221",
    cover: "/spaces/withfriends-brooklyn/cover.png",
    images: [],
    coords: [40.688081747360975, -73.9191436501984],
    host: {
      name: "Withfriends",
      batch: "W19",
      avatarUrl: "/logos/withfriends.png",
      url: "https://withfriends.co/",
    },
    listed: true,
    area: "brooklyn",
  },
  {
    title: "Plerk (S20)",
    slug: "plerk-guadalajara",
    shortAddress: "Guadalajara",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/plerk-guadalajara/cover.png",
    images: [],
    // coords: [20.659964848235354, -103.34880522915991],
    approximateCoords: [20.659964848235354, -103.34880522915991],
    host: {
      name: "Plerk",
      batch: "S20",
      avatarUrl: "/logos/plerk.png",
      url: "https://www.plerk.io/",
    },
    listed: true,
    area: "guadalajara",
  },
  {
    title: "HelpNow (W20)",
    slug: "help-now-mumbai",
    shortAddress: "Andheri East, Mumbai",
    address1: "301, 3rd Floor, Business Bay, Rd Number 11, near Aarpee Center",
    address2: "Mumbai, Maharashtra 400093",
    cover: "/spaces/help-now-mumbai/cover.png",
    images: [],
    coords: [19.122112887061483, 72.86891706875848],
    host: {
      name: "HelpNow",
      batch: "W20",
      avatarUrl: "/logos/help-now.png",
      url: "https://www.gethelpnow.in/",
    },
    listed: true,
    area: "mumbai",
  },
  {
    title: "Demigod",
    slug: "demigod-ny",
    summary: `This office is an apartment with a large room that fits up to 6 people and a living room that can be used as a lounge area or a conference room. The apartment got 6 beds, 4 bathrooms, and a fully stocked kitchen.`,
    shortAddress: "Midtown Manhattan, New York",
    address1: "853 7th Ave, #7D",
    address2: "New York, NY 10019",
    cover: "/spaces/demigod-ny/cover.jpg",
    images: Array.from({ length: 8 }).map((_, n) => ({
      url: `/spaces/demigod-ny/${n}.jpg`,
    })),
    coords: [40.7638432271322, -73.98085420191919],
    host: {
      name: "Demigod",
      batch: "",
      avatarUrl: "/logos/demigod.png",
      url: "https://www.demigod.ai/",
      about:
        "Demigod is an app that helps you build compounding habits, and improve yourself by 1% each day.",
      tags: ["Software", "Health", "Fitness"],
      joinDate: "2021-07-16",
      hiringUrl: "https://www.demigod.ai/",
      employeeCount: 11,
      contactPerson: {
        name: "Gokul Prabhakaran",
        title: "Co-founder",
        imageUrl: "/spaces/demigod-ny/gokul-prabhakaran.jpg",
      },
    },
    listed: true,
    amenities: [
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.CONFERENCE_ROOMS,
      amenities.KITCHEN,
      amenities.GYM,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 6 },
      { type: MEETING_ROOM, capacity: 6 },
      { type: KITCHEN },
    ],
    area: "ny",
  },
  {
    title: "teaBOT (S15)",
    slug: "teabot-toronto",
    shortAddress: "Niagara, Toronto",
    address1: "786 King Street West",
    address2: "Toronto, ON M5V 1M5",
    cover: "/spaces/teabot-toronto/cover.png",
    images: [],
    coords: [43.64338751520611, -79.40642247299533],
    host: {
      name: "teaBOT",
      batch: "S15",
      avatarUrl: "/logos/teabot.png",
      url: "https://teabot.com/",
    },
    listed: true,
    area: "toronto",
  },
  {
    title: "Hiration (W20)",
    slug: "hiration-new-delhi",
    shortAddress: "New Delhi",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/hiration-new-delhi/cover.png",
    images: [],
    // coords: [43.64338751520611, -79.40642247299533],
    approximateCoords: [28.613514021384375, 77.20760244427309],
    host: {
      name: "Hiration",
      batch: "W20",
      avatarUrl: "/logos/hiration.png",
      url: "https://www.hiration.com/",
    },
    listed: true,
    area: "new-delhi",
  },
  {
    title: "Juno (S19)",
    slug: "juno-toronto",
    shortAddress: "Parkdale, Toronto",
    address1: "24 Sorauren Avenue",
    address2: "Toronto, ON M6R 2C7",
    cover: "/spaces/juno-toronto/cover.png",
    images: [],
    coords: [43.64051125054834, -79.44155803066802],
    host: {
      name: "Juno",
      batch: "S19",
      avatarUrl: "/logos/juno.png",
      url: "https://junocollege.com/",
    },
    listed: true,
    area: "toronto",
  },
  {
    title: "AccioJob (W19)",
    slug: "accio-job-gurugram",
    shortAddress: "Sector 57, Gurugram",
    address1:
      "Aloha, Golf Course Extension Road, Sushant Lok III Extension, Sector 57",
    address2: "Gurugram, Haryana 122003",
    cover: "/spaces/accio-job-gurugram/cover.png",
    images: [],
    coords: [28.41628168250372, 77.07649472660039],
    host: {
      name: "AccioJob",
      batch: "W19",
      avatarUrl: "/logos/accio-job.png",
      url: "https://www.acciojob.com/",
    },
    listed: true,
    area: "gurugram",
  },
  {
    title: "Comadre",
    slug: "comadre-sao-paulo",
    shortAddress: "Vila Olímpia, São Paulo",
    address1: "Rua Fidêncio Ramos 100, 8 andar",
    address2: "São Paulo - SP, 04551-010",
    cover: "/spaces/comadre-sao-paulo/cover.png",
    images: [],
    coords: [-23.59438351659318, -46.68465063116781],
    host: {
      name: "Comadre",
      batch: "",
      avatarUrl: "/logos/comadre.png",
      url: "https://www.linkedin.com/company/comadre/",
    },
    listed: true,
    upcoming: true,
    amenities: [
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.CONFERENCE_ROOMS,
      amenities.LOUNGE,
      amenities.AIR_CONDITIONER,
    ],
    area: "sao-paulo",
  },
  {
    title: "Georgette Packaging (W16)",
    slug: "georgette-packaging-kitchener",
    summary:
      "The space has great big openable south-facing windows, 20-foot high ceilings, old hardwood floors, a toaster oven, a water cooler, etc. Come visit us!",
    shortAddress: "Mount Hope Huron Park, Kitchener",
    address1: "283 Duke St W Suite 306",
    address2: "Kitchener, ON N2H 3X7",
    cover: "/spaces/georgette-packaging-kitchener/cover.png",
    images: [],
    coords: [43.45510562633228, -80.4965215749068],
    host: {
      name: "Georgette Packaging",
      batch: "W16",
      avatarUrl: "/logos/georgette-packaging.png",
      url: "https://www.georgettepackaging.com/",
    },
    listed: true,
    area: "kitchener",
  },
  {
    title: "Zergo (S19)",
    slug: "zergo-waterloo",
    shortAddress: "Waterloo",
    address1: "3 Regina St N",
    address2: "Waterloo, ON N2J 2W7",
    cover: "/spaces/zergo-waterloo/cover.png",
    images: [],
    coords: [43.46574193964356, -80.52198564045688],
    host: {
      name: "Zergo",
      batch: "S19",
      avatarUrl: "/logos/zergo.png",
      url: "https://www.zergo.com/",
    },
    listed: true,
    area: "waterloo",
  },
  {
    title: "Skill-Lync (W19)",
    slug: "skill-lync-chennai",
    shortAddress: "Thiruvanmiyur, Chennai",
    address1: "129B, 2nd & 3rd Floor, Baid IT Park",
    address2: "Chennai, Tamil Nadu 600041",
    cover: "/spaces/skill-lync-chennai/cover.png",
    images: [],
    coords: [12.972824960154018, 80.26013363480276],
    host: {
      name: "Skill-Lync",
      batch: "W19",
      avatarUrl: "/logos/skill-lync.png",
      url: "https://skill-lync.com/",
    },
    listed: true,
    area: "chennai",
  },
  {
    title: "Avo (W19)",
    slug: "avo-reykjavik",
    shortAddress: "Miðborg, Reykjavík",
    address1: "Klapparstígur 29",
    address2: "101 Reykjavík",
    cover: "/spaces/avo-reykjavik/cover.png",
    images: [],
    coords: [64.14591432631828, -21.928808243413677],
    host: {
      name: "Avo",
      batch: "W19",
      avatarUrl: "/logos/avo.png",
      url: "https://www.avo.app/",
    },
    listed: true,
    area: "reykjavik",
  },
  {
    title: "ScholarMe (S19)",
    slug: "scholar-me-toronto",
    shortAddress: "Trinity - Bellwoods, Toronto",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/scholar-me-toronto/cover.png",
    images: [],
    approximateCoords: [43.64990361138213, -79.41556476791004],
    host: {
      name: "ScholarMe",
      batch: "S19",
      avatarUrl: "/logos/scholar-me.png",
      url: "https://scholarme.com/",
    },
    listed: true,
    area: "toronto",
  },
  {
    title: "SkydropX (S18)",
    slug: "skydropx-monterrey",
    shortAddress: "Centro, Monterrey",
    address1: "Padre Raymundo Jardón 925",
    address2: "64000 Monterrey, N.L.",
    cover: "/spaces/skydropx-monterrey/cover.png",
    images: [],
    coords: [25.66503208368073, -100.30749810229244],
    host: {
      name: "SkydropX",
      batch: "S18",
      avatarUrl: "/logos/skydropx.png",
      url: "https://www.skydropx.com/",
    },
    listed: true,
    area: "monterrey",
  },
  {
    title: "Cityfurnish (W19)",
    slug: "city-furnish-gurugram",
    shortAddress: "Sector 48, Gurugram",
    address1: "Unit No 525-527,5th floor,JMD megapolis",
    address2: "Gurugram, Haryana 122018",
    cover: "/spaces/city-furnish-gurugram/cover.png",
    images: [],
    coords: [28.419271810727544, 77.03835695543675],
    host: {
      name: "Cityfurnish",
      batch: "W19",
      avatarUrl: "/logos/city-furnish.png",
      url: "https://cityfurnish.com/",
    },
    listed: true,
    area: "gurugram",
  },
  {
    title: "GoLorry (W16)",
    slug: "go-lorry-hyderabad",

    shortAddress: "Gachibowli, Hyderabad",
    address1: "Apt 1912, Tower B, Golf Edge Residence",
    address2: "Hyderabad, Telangana 500032",
    cover: "/spaces/go-lorry-hyderabad/cover.png",
    images: [],
    coords: [17.424437159412896, 78.34756213989826],
    host: {
      name: "GoLorry",
      batch: "W16",
      avatarUrl: "/logos/go-lorry.png",
      url: "https://www.golorry.com/",
    },
    listed: true,
    area: "hyderabad",
  },
  {
    title: "Gmelius (S19)",
    slug: "gmelius-geneva",
    summary: "",
    shortAddress: "Les Avanchets, Geneva",
    address1: "Avenue Louis-Casaï 71",
    address2: "1216 Genève",
    cover: "/spaces/gmelius-geneva/cover.png",
    images: [],
    coords: [46.223737565721514, 6.107134040578275],
    host: {
      name: "Gmelius",
      batch: "S19",
      avatarUrl: "/logos/gmelius.png",
      url: "https://gmelius.com",
    },
    listed: true,
    area: "geneva",
  },
  {
    title: "Swifter (W20)",
    slug: "swifter-bengaluru",
    summary: "",
    shortAddress: "Koramangala, Bengaluru",
    address1: "Prestige Atlanta 80 Feet Main Road",
    address2: "Bengaluru, KA 560034",
    cover: "/spaces/swifter-bengaluru/cover.png",
    images: [],
    coords: [12.92894919088393, 77.63298875704389],
    host: {
      name: "Swifter",
      batch: "W20",
      avatarUrl: "/logos/swifter.png",
      url: "https://www.swifterhq.com/",
    },
    listed: true,
    area: "bengaluru",
  },
  {
    title: "Assembly",
    slug: "assembly-redwood-city",
    summary: `Office space in Redwood City facing SF Bay. A great fit for small startups, especially handling hardware or physical products.

The space offers numerous perks, amongst them plenty of free parking spaces, a warehouse, an office manager that can welcome your guests, larger and smaller conference rooms, a couple of small offices perfect for 2 to 4 people teams, a fully equipped kitchen, etc.
    
Multiple YC startups have worked from our space in the past: iCracked, getScale, Mighty Buildings, Mobius Materials, Fitter, Corvus Robotics, etc.

If you need help with any international shipping or other very specific warehouse/shipping related needs, including storage or order fulfillment, contact us as we're be able to help too!`,
    shortAddress: "Port, Redwood City",
    address1: "599 Seaport Blvd",
    address2: "Redwood City, CA 94063",
    cover: "/spaces/assembly-redwood-city/cover.jpg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/assembly-redwood-city/${n}.jpg`,
    })),
    coords: [37.507042952714, -122.20885528443996],
    host: {
      name: "Assembly",
      batch: "",
      avatarUrl: "/logos/assembly.png",
      url: "https://www.assembly.com/",
    },
    listed: true,
    amenities: [
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.WORKCHAIRS,
      amenities.CONFERENCE_ROOMS,
      amenities.KITCHEN,
      amenities.OFFICE_MANAGER,
      amenities.PING_PONG,
      amenities.ALL_DAY_ACCESS,
      "Warehouse space",
      "Lockers",
    ],
    missingAmenities: [amenities.PHONE_BOOTHS],
    area: "redwood-city",
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 15 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: MEETING_ROOM, capacity: 6 },
    ],
  },
  {
    title: "Minimall (S20)",
    slug: "minimall-paris",
    summary: "",
    shortAddress: "Gare, Paris",
    address1: "5 Rue Eugène Freyssinet",
    address2: "75013 Paris",
    cover: "/spaces/minimall-paris/cover.png",
    images: [],
    coords: [48.83390920335398, 2.371176399269057],
    host: {
      name: "Minimall",
      batch: "S20",
      avatarUrl: "/logos/minimall.png",
      url: "https://www.minimall.co",
    },
    listed: true,
    area: "paris",
  },
  {
    title: "Vitable Health (S20)",
    slug: "vitable-health-philadelphia",
    summary: "",
    shortAddress: "Northern Liberties, Philadelphia",
    address1: "1010 North Hancock Street",
    address2: "Philadelphia, PA 19123",
    cover: "/spaces/vitable-health-philadelphia/cover.png",
    images: [],
    coords: [39.9655441463508, -75.13956298606718],
    host: {
      name: "Vitable Health",
      batch: "S20",
      avatarUrl: "/logos/vitable-health.png",
      url: "https://vitablehealth.com/",
    },
    listed: true,
    area: "philadelphia",
  },
  {
    title: "Curri (S19)",
    slug: "curri-ventura",
    summary: "",
    shortAddress: "Downtown, Ventura",
    address1: "62 S Oak St",
    address2: "Ventura, CA 93001",
    cover: "/spaces/curri-ventura/cover.png",
    images: [],
    coords: [34.27998026679747, -119.29443224474761],
    host: {
      name: "Curri",
      batch: "S19",
      avatarUrl: "/logos/curri.png",
      url: "http://www.curri.com/",
    },
    listed: true,
    area: "ventura",
  },
  {
    title: "Obie (S19)",
    slug: "obie-chicago",
    summary: "",
    shortAddress: "West Loop, Chicago",
    address1: "1134 W Hubbard St",
    address2: "Chicago, IL 60642",
    cover: "/spaces/obie-chicago/cover.png",
    images: [],
    coords: [41.890163518746654, -87.65609948839435],
    host: {
      name: "Obie",
      batch: "S19",
      avatarUrl: "/logos/obie.png",
      url: "https://www.obierisk.com/",
    },
    listed: true,
    area: "chicago",
  },
  {
    title: "Aerones (W18)",
    slug: "aerones-riga",
    summary:
      "Space fully equipped for hardware work: 3d printer, electronic tools, soldering and CNC machine based on availability, etc.",
    shortAddress: "Latgale Suburb, Riga",
    address1: "Katlakalna iela 11",
    address2: "Rīga, LV-1073",
    cover: "/spaces/aerones-riga/cover.png",
    images: [],
    coords: [56.93006843958193, 24.19976431159102],
    host: {
      name: "Aerones",
      batch: "W18",
      avatarUrl: "/logos/aerones.png",
      url: "https://www.aerones.com/",
    },
    listed: true,
    amenities: [
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.WORKCHAIRS,
      "Workshop Tools",
      "3D Printer",
    ],
    area: "riga",
  },
  {
    title: "Boundary Layer Technologies (W19)",
    slug: "boundary-layer-tech-alameda",
    summary:
      "Ever wondered what it feels like to work from a Shipyard? Join us for a day to find out!",
    shortAddress: "Alameda",
    address1: "2900 Main St",
    address2: "Alameda, CA 94501",
    cover: "/spaces/boundary-layer-tech-alameda/cover.png",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/boundary-layer-tech-alameda/${n}.jpg`,
    })),
    coords: [37.789182871807455, -122.29180409576833],
    host: {
      name: "Boundary Layer Technologies",
      batch: "W19",
      avatarUrl: "/logos/boundary-layer-tech.png",
      url: "https://www.boundarylayer.tech/",
    },
    listed: true,
    area: "alameda",
  },
  {
    title: "PingPong (W21)",
    slug: "pingpong-provo",
    summary: "",
    shortAddress: "Provo, Utah",
    address1: "unknown",
    address2: "unknown",
    cover: "/spaces/pingpong-provo/cover.png",
    images: [],
    // coords: [41.890163518746654, -87.65609948839435],
    approximateCoords: [40.234744571164335, -111.6544499329422],
    host: {
      name: "PingPong",
      batch: "W21",
      avatarUrl: "/logos/pingpong.png",
      url: "https://www.getpingpong.com/",
    },
    listed: true,
    area: "provo",
  },
  {
    title: "Bloomboard (IK12)",
    slug: "bloomboard-pittsburgh",
    summary: "",
    shortAddress: "Shadyside, Pittsburgh",
    address1: "5401 Walnut Avenue, Suite 200",
    address2: "Pittsburgh, PA 15232",
    cover: "/spaces/bloomboard-pittsburgh/cover.png",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/bloomboard-pittsburgh/${n}.jpeg`,
    })),
    coords: [40.45081873807525, -79.93531609886286],
    host: {
      name: "Bloomboard",
      batch: "IK12",
      avatarUrl: "/logos/bloomboard.png",
      url: "https://bloomboard.com/",
    },
    listed: true,
    area: "pittsburgh",
  },
  {
    title: "Pulse Active Stations (W19)",
    slug: "pulse-active-hyderabad",
    summary: "",
    shortAddress: "Jubilee Hills, Hyderabad",
    address1: "Plot no. 28, Rd Number 76, Jubilee Hills",
    address2: "Hyderabad - 500 033",
    cover: "/spaces/pulse-active-hyderabad/cover.png",
    images: [{ url: "/spaces/pulse-active-hyderabad/0.jpeg" }],
    coords: [17.417368784369348, 78.40905593521707],
    host: {
      name: "Pulse Active Stations",
      batch: "W19",
      avatarUrl: "/logos/pulse-active.png",
      url: "http://www.getpulse.in/",
    },
    listed: true,
    amenities: [
      amenities.WIFI,
      amenities.FREE_BEVERAGES,
      amenities.WORKCHAIRS,
      amenities.KITCHEN,
      amenities.CONFERENCE_ROOMS,
      amenities.PHONE_BOOTHS,
      amenities.AIR_CONDITIONER,
      amenities.RECEPTION,
    ],
    area: "hyderabad",
  },
  {
    title: "Statiq. (S20)",
    slug: "statiq-gurugram",
    summary: "",
    shortAddress: "Sector 15, Gurugram",
    address1: "Wework 32nd Milestone, 3rd Floor",
    address2: "Haryana 122001",
    cover: "/spaces/statiq-gurugram/cover.png",
    images: [],
    coords: [28.462779379960036, 77.04914353254404],
    host: {
      name: "Statiq.",
      batch: "S20",
      avatarUrl: "/logos/statiq.png",
      url: "https://www.statiq.in/",
    },
    listed: true,
    area: "gurugram",
  },
  {
    title: "VNTRS",
    slug: "vntrs-sthlm",
    summary: `Bright open space location. Walking distance to Fridhemsplan area, filled with shops and restaurants!

This whole floor office space also offers a small lounge area, 5 conference rooms and 1 phone booth (Room). There are also 3 restrooms, a large kitchen that is equipped with a freezer/refrigerator, microwave and dishwasher, a game room. Included access to coffee & espresso, but also a great selection of teas.
    
Lastly, but definitely not least, the office has a huge rooftop area where you can chill at the end of the day or have a little company get-togehter on a sunny day.`,
    shortAddress: "S:t Eriksområdet, Kungsholmen",
    address1: "Alströmergatan 22",
    address2: "112 47 Stockholm",
    cover: "/spaces/vntrs-sthlm/cover.jpg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/vntrs-sthlm/${n}.jpg`,
    })),
    coords: [59.336300141779255, 18.028013452687002],
    host: {
      name: "VNTRS",
      avatarUrl: "/logos/vntrs.png",
      url: "https://vntrs.com/",
      about:
        "VNTRS (Ventures) is a Nordic-Baltic venture studio investing in tech startup companies. With our experienced team of digital consultants, we help tech companies in early stages build their product and their business.",
      tags: ["Consulting"],
      joinDate: "2021-06-01",
      hiringUrl: "https://career.vntrs.se/",
      employeeCount: 32,
      contactPerson: {
        name: "Amanda Augustsson",
        title: "Office Manager",
        imageUrl: "/spaces/vntrs-sthlm/amanda-augustsson.png",
      },
    },
    listed: true,
    area: "stockholm",
    accessInstructions:
      "Access to the space is provided via the codes: 2813 + OK or after 6pm 4677 + OK",
    wifi: {
      ssid: "VNTRS HQ",
      password: "Sommar2019%",
    },
    amenities: [
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.WIFI,
      amenities.MEETING_ROOMS,
      amenities.STANDING_DESKS,
      amenities.PHONE_BOOTHS,
      amenities.FREE_BEVERAGES,
      amenities.PET_FRIENDLY,
      amenities.BOARD_GAMES,
      "Rooftop",
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 10 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 5 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: PHONE_BOOTH, count: 1 },
      { type: KITCHEN },
    ],
    meetingRooms: {
      link: "https://vntrs.skedda.com/register?key=f78ce1c&payload=CfDJ8Crhf3oQwftCizhofW-GxUJRIy1McahSSgkN6lzjxou6JMeOte-QlhiwKBg5hfIda94vlQm1HwlYnlJpsERaOKT90UM9lOK7sXYpt7AIvnB10_5zIVwenCXv9cURbwPHiQ",
    },
  },
  {
    title: "Houm (W21)",
    slug: "houm-santiago",
    summary: "",
    shortAddress: "Huechuraba, Santiago de Chile",
    address1: "Av. del Parque 5275, oficina 504",
    address2: "8580000 Huechuraba, Chile",
    cover: "/spaces/houm-santiago/cover.png",
    images: Array.from({ length: 5 }).map((_, n) => ({
      url: `/spaces/houm-santiago/${n}.jpg`,
    })),
    coords: [-33.38727523469364, -70.61444068853208],
    host: {
      name: "Houm",
      batch: "W21",
      avatarUrl: "/logos/houm.png",
      url: "https://houm.com/",
    },
    listed: true,
    area: "santiago",
  },
  {
    title: "Bamboo (W20)",
    slug: "bamboo-lagos",
    summary: "",
    shortAddress: "Lekki Phase 1, Lagos",
    address1: "Plot 1, Block, 22 Babatunde Anjous Avenue",
    address2: "Lekki Phase 1, Lagos, Nigeria",
    cover: "/spaces/bamboo-lagos/cover.png",
    images: Array.from({ length: 5 }).map((_, n) => ({
      url: `/spaces/bamboo-lagos/${n}.jpg`,
    })),
    coords: [6.449665487810951, 3.4775775153368316],
    host: {
      name: "Bamboo",
      batch: "W20",
      avatarUrl: "/logos/bamboo.png",
      url: "https://investbamboo.com/",
    },
    listed: true,
    area: "lagos",
  },
  {
    title: "Gatsby Enterprises",
    slug: "gatsby-ny",
    summary: "",
    shortAddress: "Midtown Manhattan, New York",
    address1: "224 West 35th Street",
    address2: "New York, NY 10001",
    cover: "/spaces/gatsby-ny/cover.jpg",
    images: Array.from({ length: 3 }).map((_, n) => ({
      url: `/spaces/gatsby-ny/${n}.jpg`,
    })),
    coords: [40.75211735770913, -73.99106791558296],
    host: {
      name: "Gatsby Enterprises",
      avatarUrl: "/logos/gatsby-ny.png",
      url: "https://www.gatsbyrealty.com/",
    },
    capacity: 12,
    similarLocations: [],
    amenities: [
      amenities.WIFI,
      amenities.ALL_DAY_ACCESS,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.CONFERENCE_ROOMS,
      amenities.KITCHEN,
    ],
    accessInstructions: "",
    upcoming: true,
    listed: true,
    area: "ny",
  },
  {
    title: "ThereCraft (S17)",
    slug: "therecraft-iceland",
    shortAddress: "Reykjavík, Iceland",
    address1: "",
    address2: "",
    cover: "/spaces/therecraft-iceland/cover.png",
    images: [],
    approximateCoords: [64.14660519771131, -21.942366820790333],
    host: {
      name: "ThereCraft",
      batch: "S17",
      avatarUrl: "/logos/therecraft.png",
      url: "https://therecraft.com/",
    },
    listed: true,
    area: "reykjavik",
  },
  {
    title: "Vendease (W21)",
    slug: "vendease-lagos",
    shortAddress: "Lekki Phase 1, Lagos",
    address1: "Plot 1A, Block 143, Edward Hotonu Street",
    address2: "Lekki Phase 1, Lagos, Nigeria",
    cover: "/spaces/vendease-lagos/cover.png",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/vendease-lagos/${n}.jpg`,
    })),
    approximateCoords: [6.441906228125181, 3.4693880147243563],
    host: {
      name: "Vendease",
      batch: "W21",
      avatarUrl: "/logos/vendease.png",
      url: "https://vendease.com/",
    },
    listed: true,
    area: "lagos",
    amenities: [
      amenities.WIFI,
      amenities.ALL_DAY_ACCESS,
      amenities.STANDING_DESKS,
      amenities.WORKCHAIRS,
      amenities.KITCHEN,
      amenities.PING_PONG,
      amenities.BOARD_GAMES,
    ],
  },
  {
    title: "Triomics (W21)",
    slug: "triomics-gurugram",
    shortAddress: "Ardee City, Gurugram",
    address1: "B-51, Ardee City, Sector 52",
    address2: "Gurugram, Haryana 122001, India",
    cover: "/spaces/triomics-gurugram/cover.png",
    images: [],
    coords: [28.440645982498726, 77.08052726890985],
    host: {
      name: "Triomics",
      batch: "W21",
      avatarUrl: "/logos/triomics.png",
      url: "https://triomics.in/",
    },
    listed: true,
    area: "gurugram",
  },
  {
    title: "FanPlay (W21)",
    slug: "fanplay-new-delhi",
    shortAddress: "New Delhi",
    address1: "B-7, First Floor, Sector 2, Noida",
    address2: "Uttar Pradesh 201301, India",
    cover: "/spaces/fanplay-new-delhi/cover.png",
    images: [],
    approximateCoords: [28.572019464961418, 77.33765501631297],
    host: {
      name: "FanPlay",
      batch: "W21",
      avatarUrl: "/logos/fanplay.png",
      url: "https://www.fangame.live/",
    },
    listed: true,
    area: "new-delhi",
  },
  {
    title: "Greywing (W21)",
    slug: "greywing-singapore",
    shortAddress: "Singapore River, Singapore",
    address1: "32 Carpenter Street",
    address2: "Singapore 059911",
    cover: "/spaces/greywing-singapore/cover.png",
    images: [],
    coords: [1.288144576120877, 103.84756634092368],
    host: {
      name: "Greywing",
      batch: "W21",
      avatarUrl: "/logos/greywing.png",
      url: "https://grey-wing.com/",
    },
    listed: true,
    area: "singapore",
  },
  {
    title: "Filadd",
    slug: "filadd-cordoba",
    shortAddress: "Nueva Córdoba, Córdoba",
    address1: "Ituzaingó 1437",
    address2: "X5000IKC Córdoba, Argentina",
    cover: "/spaces/filadd-cordoba/cover.png",
    images: [],
    coords: [-31.433411618572972, -64.18809177210714],
    host: {
      name: "Filadd",
      batch: "",
      avatarUrl: "/logos/filadd.png",
      url: "https://filadd.com/",
    },
    listed: true,
    area: "cordoba",
  },
  {
    title: "OpenBiome",
    slug: "openbiome-cambridge",
    summary: `Gorgeous open office with plenty of light and space for your team to work. Equipped with 3 conference rooms, 4 meeting rooms, a modern privacy phone booth, treadmill desk and breakout area. There is also a mother's room and a large fully equipped kitchen.
    
Located in Cambridge, 5 blocks from Red Line & Commuter Rail (Fitchburg Line), this prime area could be the perfect location for you and your team to thrive.`,
    shortAddress: "Porter Square, Cambridge",
    address1: "2067 Massachusetts Ave",
    address2: "Cambridge, MA 02140",
    cover: "/spaces/openbiome-cambridge/cover.jpg",
    images: Array.from({ length: 7 }).map((_, n) => ({
      url: `/spaces/openbiome-cambridge/${n}.jpg`,
    })),
    coords: [42.39157971462928, -71.12290228519318],
    host: {
      name: "OpenBiome",
      avatarUrl: "/logos/openbiome.png",
      url: "https://openbiome.org/",
      about:
        "OpenBiome's mission is to expand access to fecal microbiota transplantations (FMT) and help lead groundbreaking research into the human microbiome.",
      tags: ["Biotechnology", "Nonprofit"],
      joinDate: "2021-08-06",
      hiringUrl: "https://www.openbiome.org/jobs",
      employeeCount: 20,
      contactPerson: {
        name: "Cyleigh Mertz",
        title: "Accounting Manager",
        imageUrl: "/spaces/openbiome-cambridge/cyleigh-mertz.png",
      },
    },
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.CONFERENCE_ROOMS,
      amenities.PHONE_BOOTHS,
      amenities.KITCHEN,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 8 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: PHONE_BOOTH, count: 1 },
      { type: KITCHEN },
    ],
    accessInstructions: "",
    listed: true,
    area: "cambridge",
  },
  {
    title: "Addicting Games",
    slug: "addictinggames-la",
    summary: `Bright office in a superb location in West LA. Perfect for individuals or small teams wanting to work together.

This is a new office space with loads of natural light and a big open area with a kitchenette and lounge space. There's free street parking and a dedicated paring spot can be discussed as well.
    
The neighborhood is vibrant with multiple cafes and restaurantes in walking distance, and a number of Japanese markets and restaurants nearby.`,
    shortAddress: "West Los Angeles",
    address1: "2340 S Centinela Ave",
    address2: "Los Angeles, CA 90064",
    cover: "/spaces/addictinggames-la/cover.jpg",
    images: Array.from({ length: 8 }).map((_, n) => ({
      url: `/spaces/addictinggames-la/${n}.jpg`,
    })),
    coords: [34.02859283161136, -118.45375527072262],
    //
    host: {
      name: "Addicting Games",
      avatarUrl: "/logos/addictinggames.png",
      url: "https://company.addictinggames.com/",
      about:
        "Addicting Games is the largest online games site in the US. Reaching over 10 million unique users every month (comScore). We are considered founders in the casual game territory, developing and distributing innovative, irreverent, addictive online games since the early 2000s.",
      tags: ["Software", "Gaming"],
      joinDate: "2021-07-12",
      hiringUrl: "https://www.indeed.com/cmp/Addicting-Games/jobs",
      employeeCount: 15,
      contactPerson: {
        name: "Bill Karamouzis",
        title: "Founder",
        imageUrl: "/spaces/addictinggames-la/bill-karamouzis.jpg",
      },
    },
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.KITCHEN,
      amenities.FREE_PARKING,
      amenities.LOUNGE,
      amenities.MEETING_ROOMS,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 5 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: KITCHEN },
    ],
    accessInstructions: "",
    listed: true,
    area: "la",
  },
  {
    title: "Stress-Free Auto Care",
    slug: "stress-free-mountain-view",
    summary: `The ideal office for a team in need of a large open compartmented space, vehicle lifts, and a 1000 square feet parking area.
    
Contact Satchel if you're interested in short term access to warehouse/workshop spaces`,
    shortAddress: "Mountain View, CA",
    address1: "2587 Wyandotte St",
    address2: "Mountain View, CA 94043",
    cover: "/spaces/stress-free-mountain-view/cover.jpg",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/stress-free-mountain-view/${n}.jpg`,
    })),
    coords: [37.416551401076056, -122.10136047049417],
    host: {
      name: "Stress-Free Auto Care",
      batch: "",
      avatarUrl: "/logos/stress-free.png",
      url: "https://www.stressfree.com/",
    },
    capacity: 5,
    similarLocations: [],
    upcoming: true,
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.RECEPTION,
      amenities.KITCHEN,
      "Mechanical room",
      "Vehicle lifts",
      "Test Driving Area",
    ],
    accessInstructions: "",
    listed: true,
    area: "mountain-view",
    matterportUrl: "https://my.matterport.com/show/?m=KXpadMaWfXy",
  },
  {
    title: "Moichor",
    slug: "moichor-sf",
    summary: `Modern loft-style office space in the prime area of San Francisco with lab equipment and two rentable break rooms.`,
    shortAddress: "Potrero Hill, San Francisco",
    address1: "555 De Haro St., Suite 220",
    address2: "San Francisco, CA, 94107",
    cover: "/spaces/moichor-sf/cover.jpg",
    images: Array.from({ length: 11 }).map((_, n) => ({
      url: `/spaces/moichor-sf/${n}.jpg`,
    })),
    coords: [37.7626090106015, -122.40075519333058],
    host: {
      name: "Moichor",
      batch: "",
      avatarUrl: "/logos/moichor.png",
      url: "https://www.moichor.com/",
      about:
        "We are a tech-centered animal diagnostics company. Our mission is to help veterinarians lengthen the lives of their patients by applying deep learning to the diagnostics they use daily.",
      tags: ["Software", "AI", "Animal care"],
      joinDate: "2021-07-13",
      hiringUrl: "https://angel.co/company/moichor/jobs",
      employeeCount: 11,
      contactPerson: {
        name: "Matthew Chen",
        title: "Co-founder/COO",
        imageUrl: "/spaces/moichor-sf/matthew-chen.jpg",
      },
    },
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.KITCHEN,
      amenities.LOUNGE,
      amenities.MEETING_ROOMS,
      "Lab Equipment",
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 2 },
      { type: MEETING_ROOM, capacity: 2 },
      { type: MEETING_ROOM, capacity: 2 },
      { type: LOUNGE_AREA },
      { type: KITCHEN },
    ],
    accessInstructions: "",
    listed: true,
    area: "sf",
  },
  {
    title: "The Brass Factory",
    slug: "the-brass-factory-ny",
    summary: `The Brass Factory is a bright and gorgeous space located in prime Williamsburg. We offer flexible, fully furnished workspaces designed to scale with your needs.
  
The abundant natural light, generous space, and lush greenery, foster an inspiring work environment you'll love to be a part of.`,
    shortAddress: "North Williamsburg, New York",
    address1: "185 Wythe Ave 2nd Floor",
    address2: "Brooklyn, NY 11249",
    cover: "/spaces/the-brass-factory-ny/cover.jpg",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/the-brass-factory-ny/${n}.jpg`,
    })),
    coords: [40.7180345078615, -73.96180760813007],
    host: {
      name: "The Brass Factory",
      avatarUrl: "/logos/the-brass-factory.png",
      url: "https://www.thebrassfactory.com/",
      about:
        "The Brass Factory is a member community and independent co-working space.",
      tags: ["Coworking"],
      joinDate: "2021-08-11",
    },
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.KITCHEN,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
      amenities.PHONE_BOOTHS,
      amenities.PRINTER,
      amenities.OFFICE_MANAGER,
      amenities.MAIL_HANDLING,
      "Bike storage",
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 8 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: PHONE_BOOTH, count: 5 },
      { type: LOUNGE_AREA },
      { type: KITCHEN },
    ],
    accessInstructions: "",
    listed: true,
    area: "ny",
  },
  {
    title: "Aidi",
    slug: "aidi-africa-lagos",
    summary: `Office located in Lagos with a private office for short-term rent, a conference room, and a shared lounge area.`,
    shortAddress: "Abule Okuta, Lagos",
    address1: "1 Towobola Street",
    address2: "Gbagada Phase Two, Lagos",
    cover: "/spaces/aidi-africa-lagos/cover.jpg",
    images: Array.from({ length: 5 }).map((_, n) => ({
      url: `/spaces/aidi-africa-lagos/${n}.jpg`,
    })),
    coords: [6.548318869222087, 3.3906433811978656],
    host: {
      name: "Aidi Africa",
      batch: "",
      avatarUrl: "/logos/aidi-africa.png",
      url: "https://aidi.africa/",
      about:
        "Aidi helps African tech founders access support from a network of business angels and large corporates.",
      joinDate: "2021-07-16",
      contactPerson: {
        name: "Emmanuel Gbolade",
        title: "Partner",
        imageUrl: "/spaces/aidi-africa-lagos/emmanuel-gbolade.jpg",
      },
    },
    capacity: 12,
    similarLocations: [],
    upcoming: true,
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.KITCHEN,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 12 },
      { type: MEETING_ROOM, capacity: 6 },
      { type: KITCHEN },
      { type: LOUNGE_AREA },
      { type: PRIVATE_OFFICE },
    ],
    accessInstructions: "",
    listed: true,
    area: "lagos",
  },
  {
    title: "Sora ID",
    slug: "sora-id-cambridge",
    shortAddress: "Cambridgeport, Cambridge",
    address1: "810 Memorial Drive, Suite 107",
    address2: "Cambridge, MA 02139",
    cover: "/spaces/sora-id-cambridge/cover.jpg",
    images: Array.from({ length: 3 }).map((_, n) => ({
      url: `/spaces/sora-id-cambridge/${n}.jpg`,
    })),
    coords: [42.36119300637105, -71.11527194348221],
    host: {
      name: "Sora ID",
      batch: "",
      avatarUrl: "/logos/sora-id.png",
      url: "https://www.soraid.com/",
      about:
        "Sora securely links real-life identities to user devices, building a new model for identity on the internet.",
      tags: ["Software", "Security", "Identity"],
      joinDate: "2021-07-13",
      hiringUrl: "https://angel.co/company/soraid/jobs",
      employeeCount: 5,
      contactPerson: {
        name: "Vamsi Varanasi",
        title: "Co-founder/COO",
        imageUrl: "/spaces/sora-id-cambridge/vamsi-varanasi.jpg",
      },
    },
    upcoming: true,
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.FREE_BEVERAGES,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.GYM,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 6 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: MEETING_ROOM, capacity: 2 },
      { type: KITCHEN },
    ],
    accessInstructions: `On your first visit you get a key fob that is used for the main building (not needed between 9-5) and there's a physical key to the office itself`,
    listed: true,
    area: "cambridge",
  },
  {
    title: "Thunderpod (W20)",
    slug: "thunderpod-mumbai",
    shortAddress: "Sector 8, Thane",
    address1: "Bungalow no 25, Shreesh CHS, Hajuri Darga road Thane",
    address2: "Mumbai, Maharashtra 400604",
    cover: "/spaces/thunderpod-mumbai/cover.png",
    images: [],
    coords: [19.19823740772873, 72.94900882338467],
    host: {
      name: "Thunderpod",
      batch: "W20",
      avatarUrl: "/logos/thunderpod.png",
      url: "https://www.thunderpod.com/",
    },
    amenities: [amenities.WIFI, amenities.WORKCHAIRS, amenities.FREE_BEVERAGES],
    accessInstructions: "",
    listed: true,
    area: "mumbai",
  },
  {
    title: "TrueNorth",
    slug: "truenorth-sf",
    summary: `Open space with good natural light in central San Francisco, ideal for medium-sized teams.`,
    shortAddress: "Mission District, San Francisco",
    address1: "3002 19th St, unit 201",
    address2: "San Francisco, CA 94110",
    cover: "/spaces/truenorth-sf/cover.jpg",
    images: Array.from({ length: 2 }).map((_, n) => ({
      url: `/spaces/truenorth-sf/${n}.jpg`,
    })),
    coords: [37.760238272550346, -122.41200060039394],
    host: {
      name: "TrueNorth",
      avatarUrl: "/logos/truenorth.jpg",
      url: "https://www.truenorthfleet.com/",
      about:
        "TrueNorth are building the tools that hundreds of thousands of independent truckers deserve to run their business.",
      tags: ["Software", "Logistics"],
      joinDate: "2021-07-27",
      hiringUrl: "https://angel.co/company/truenorth/jobs",
      employeeCount: 28,
      contactPerson: {
        name: "Angie Ruiz-Mitchell",
        title: "Executive assistant",
        imageUrl: "/spaces/truenorth-sf/angie-ruiz-mitchell.jpg",
      },
    },
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.PET_FRIENDLY,
      amenities.KITCHEN,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 10 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: KITCHEN },
    ],
    accessInstructions: "",
    listed: true,
    area: "sf",
  },
  {
    listed: true,
    title: "SHARED",
    slug: "shared-sf",
    summary: `SHARED has all the office amenities of a traditional coworking space and added to a maker space. It has fabrication space and a shared workshop with tools and equipment. SHARED also has three private spaces available for lease.`,
    shortAddress: "SoMa, San Francisco",
    address1: "739 Bryant Street",
    address2: "San Francisco, CA 94107",
    cover: "/spaces/shared-sf/cover.jpg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/shared-sf/${n}.jpg`,
    })),
    coords: [37.77690228608077, -122.40095786774052],
    host: {
      name: "SHARED",
      avatarUrl: "/logos/shared.png",
      url: "https://www.shared-sf.com/",
      about:
        "SHARED is the most unique coworking space in San Francisco, located in the heart of SoMa. Think of it as Tech Shop meets coworking.",
      tags: ["Coworking"],
      joinDate: "2021-08-06",
      contactPerson: {
        name: "Marilyn Yu",
        title: "Director",
        imageUrl: "/spaces/shared-sf/marilyn-yu.jpg",
      },
    },
    amenities: [
      amenities.WIFI,
      amenities.STANDING_DESKS,
      amenities.WORKCHAIRS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.SELF_CHECK_IN,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
      "Workshop space",
      "Toolshed",
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 10 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: PRIVATE_OFFICE },
      { type: LOUNGE_AREA },
      { type: KITCHEN },
    ],
    accessInstructions: `You get a keycard on your first visit.`,
    area: "sf",
  },
  {
    listed: true,
    title: "350 Townsend, Suite 315",
    slug: "350-townsend-suite-315-sf",
    summary: `Private office space in the heart of SoMa, perfect for a team of 3 people.`,
    shortAddress: "SoMa, San Francisco",
    address1: "350 Townsend St",
    address2: "San Francisco, CA 94107, USA",
    cover: "/spaces/350-townsend-suite-315-sf/cover.jpg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/350-townsend-suite-315-sf/${n}.jpg`,
    })),
    coords: [37.776270875967455, -122.39650604964397],
    host: {
      name: "Townsend Enterprises",
      avatarUrl: "/logos/350-townsend.jpg",
      url: "https://www.350townsend.com/",
      about:
        "350 Townsend has the most flexible private office rentals in SoMa, San Francisco.",
      joinDate: "2021-08-12",
      employeeCount: 5,
      contactPerson: {
        name: "Lisa Groger Portillo",
        title: "General Manager",
        imageUrl: "/spaces/350-townsend-suite-315-sf/lisa-groger-portillo.jpg",
      },
    },
    wifi: {
      ssid: "ATTEhWnF5s",
      password: "?unnu?hg8j5p",
    },
    accessInstructions: `You get a key fob which allows you to access the building 24x7.`,
    area: "sf",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
      amenities.FREE_BEVERAGES,
      amenities.VENDING_MACHINES,
      amenities.SELF_CHECK_IN,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
      amenities.RECEPTION,
      amenities.AIR_CONDITIONER,
      "Janitorial services",
    ],
    rooms: [
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 20 },
      { type: LOUNGE_AREA },
      { type: RECEPTION },
      { type: KITCHEN },
    ],
  },
  {
    listed: true,
    title: "350 Townsend, Suite 280",
    slug: "350-townsend-suite-280-sf",
    summary: `Private office space in the heart of SoMa, perfect for a team of 3 to 4 people.`,
    shortAddress: "SoMa, San Francisco",
    address1: "350 Townsend St",
    address2: "San Francisco, CA 94107, USA",
    cover: "/spaces/350-townsend-suite-280-sf/cover.jpg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/350-townsend-suite-280-sf/${n}.jpg`,
    })),
    coords: [37.776270875967455, -122.39650604964397],
    host: {
      name: "Townsend Enterprises",
      avatarUrl: "/logos/350-townsend.jpg",
      url: "https://www.350townsend.com/",
      about:
        "350 Townsend has the most flexible private office rentals in SoMa, San Francisco.",
      joinDate: "2021-08-12",
      employeeCount: 5,
      contactPerson: {
        name: "Lisa Groger Portillo",
        title: "General Manager",
        imageUrl: "/spaces/350-townsend-suite-280-sf/lisa-groger-portillo.jpg",
      },
    },
    wifi: {
      ssid: "Cospot Cafe",
      password: "hotspot123",
    },
    accessInstructions: `You get a key fob which allows you to access the building 24x7.`,
    area: "sf",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
      amenities.FREE_BEVERAGES,
      amenities.VENDING_MACHINES,
      amenities.SELF_CHECK_IN,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
      amenities.RECEPTION,
      amenities.AIR_CONDITIONER,
      "Janitorial services",
    ],
    rooms: [
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 20 },
      { type: LOUNGE_AREA },
      { type: RECEPTION },
      { type: KITCHEN },
    ],
  },
  {
    listed: true,
    title: "350 Townsend, Suite 421",
    slug: "350-townsend-suite-421-sf",
    summary: `Private office space in the heart of SoMa, perfect for a team of 6 to 8 people.`,
    shortAddress: "SoMa, San Francisco",
    address1: "350 Townsend St",
    address2: "San Francisco, CA 94107, USA",
    cover: "/spaces/350-townsend-suite-421-sf/cover.jpg",
    images: Array.from({ length: 11 }).map((_, n) => ({
      url: `/spaces/350-townsend-suite-421-sf/${n}.jpg`,
    })),
    coords: [37.776270875967455, -122.39650604964397],
    host: {
      name: "Townsend Enterprises",
      avatarUrl: "/logos/350-townsend.jpg",
      url: "https://www.350townsend.com/",
      about:
        "350 Townsend has the most flexible private office rentals in SoMa, San Francisco.",
      joinDate: "2021-08-12",
      employeeCount: 5,
      contactPerson: {
        name: "Lisa Groger Portillo",
        title: "General Manager",
        imageUrl: "/spaces/350-townsend-suite-421-sf/lisa-groger-portillo.jpg",
      },
    },
    wifi: {
      ssid: "HQATTtDuy5vS",
      password: "3nsgkdi2eayk",
    },
    accessInstructions: `You get a key fob which allows you to access the building 24x7.`,
    area: "sf",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
      amenities.FREE_BEVERAGES,
      amenities.VENDING_MACHINES,
      amenities.SELF_CHECK_IN,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
      amenities.RECEPTION,
      amenities.AIR_CONDITIONER,
      "Janitorial services",
    ],
    rooms: [
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 20 },
      { type: LOUNGE_AREA },
      { type: RECEPTION },
      { type: KITCHEN },
    ],
  },
  {
    listed: true,
    title: "350 Townsend, Suite 220",
    slug: "350-townsend-suite-220-sf",
    summary: `Private office space in the heart of SoMa, perfect for a team of 6 people.`,
    shortAddress: "SoMa, San Francisco",
    address1: "350 Townsend St",
    address2: "San Francisco, CA 94107, USA",
    cover: "/spaces/350-townsend-suite-220-sf/cover.jpg",
    images: Array.from({ length: 10 }).map((_, n) => ({
      url: `/spaces/350-townsend-suite-220-sf/${n}.jpg`,
    })),
    coords: [37.776270875967455, -122.39650604964397],
    host: {
      name: "Townsend Enterprises",
      avatarUrl: "/logos/350-townsend.jpg",
      url: "https://www.350townsend.com/",
      about:
        "350 Townsend has the most flexible private office rentals in SoMa, San Francisco.",
      joinDate: "2021-08-12",
      employeeCount: 5,
      contactPerson: {
        name: "Lisa Groger Portillo",
        title: "General Manager",
        imageUrl: "/spaces/350-townsend-suite-220-sf/lisa-groger-portillo.jpg",
      },
    },
    accessInstructions: `You get a key fob which allows you to access the building 24x7.`,
    area: "sf",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
      amenities.FREE_BEVERAGES,
      amenities.VENDING_MACHINES,
      amenities.SELF_CHECK_IN,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
      amenities.RECEPTION,
      amenities.AIR_CONDITIONER,
      "Janitorial services",
    ],
    rooms: [
      { type: MEETING_ROOM, capacity: 6 },
      { type: MEETING_ROOM, capacity: 10 },
      { type: MEETING_ROOM, capacity: 20 },
      { type: LOUNGE_AREA },
      { type: RECEPTION },
      { type: KITCHEN },
    ],
  },
  {
    listed: true,
    title: "Doorhaus",
    slug: "doorhaus-sf",
    summary: `Doorhaus has a 2000 sqft office space ideal for small to mid-size teams looking for workspace in the heart of the Mission.

This fully furnished office has plenty of natural light, high ceilings and gives you access to all the needed amenities that foster an inspiring work environment. 
    
Doorhaus also offers five private spaces in different sizes available for lease, contact us on [bookings@heysatchel.com](mailto:bookings@heysatchel.com) for more information.`,
    shortAddress: "Mission District, San Francisco",
    address1: "2431 Mission St, 2nd Floor ",
    address2: "San Francisco, CA 94110",
    area: "sf",
    cover: "/spaces/doorhaus-sf/cover.jpg",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/doorhaus-sf/${n}.jpg`,
    })),
    coords: [37.758086495951076, -122.41887188590704],
    host: {
      name: "Doorhaus",
      avatarUrl: "/logos/doorhaus.png",
      about: "Doorhaus provides office space for creatives and businesses.",
      joinDate: "2021-08-16",
      contactPerson: {
        name: "Dan Schmeltzer",
        title: "Co-founder",
        imageUrl: "/spaces/doorhaus-sf/dan-schmeltzer.jpg",
      },
    },
    accessInstructions: "",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.SELF_CHECK_IN,
      amenities.KITCHEN,
      amenities.AIR_CONDITIONER,
      amenities.PET_FRIENDLY,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 15 },
      { type: PRIVATE_OFFICE, capacity: 6 },
      { type: PRIVATE_OFFICE, capacity: 4 },
      { type: PRIVATE_OFFICE, capacity: 4 },
      { type: PRIVATE_OFFICE, capacity: 3 },
      { type: PRIVATE_OFFICE, capacity: 3 },
      { type: KITCHEN },
    ],
  },
  {
    listed: true,
    title: "EMOTIV",
    slug: "emotiv-sf",
    summary: `EMOTIV has a 2222 sqft office space ideal for small to mid-size teams looking for workspace in central Union Square.

This office is fully furnished with modern furnishing, has lots of natural light and gives access to all the needed amenities that foster an inspiring work environment. Some of the amenities are a modern kitchen, video conferencing facilities, and nightly janitorial services.
    
EMOTIV also offers 4 private spaces in different sizes available for lease, contact us on [bookings@heysatchel.com](mailto:bookings@heysatchel.com) for more information. Monthly parking is available for an additional fee.`,
    shortAddress: "Union Square, San Francisco",
    address1: "490 Post Street ",
    address2: "San Francisco, CA 94102",
    area: "sf",
    cover: "/spaces/emotiv-sf/cover.jpg",
    images: Array.from({ length: 4 }).map((_, n) => ({
      url: `/spaces/emotiv-sf/${n}.jpg`,
    })),
    coords: [37.78831123532004, -122.40914774836124],
    host: {
      name: "EMOTIV",
      avatarUrl: "/logos/emotiv.png",
      url: "https://www.emotiv.com/",
      about:
        "EMOTIV is a bioinformatics company advancing understanding of the human brain using electroencephalography (EEG).",
      tags: ["Biotechnology"],
      joinDate: "2021-08-16",
      hiringUrl: "https://www.emotiv.com/careers/",
      employeeCount: 60,
      contactPerson: {
        name: "Tan Le",
        title: "CEO",
        imageUrl: "/spaces/emotiv-sf/tan-le.jpg",
      },
    },
    accessInstructions: "",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.CONFERENCE_ROOMS,
      amenities.FREE_BEVERAGES,
      amenities.SELF_CHECK_IN,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
      amenities.RECEPTION,
      amenities.AIR_CONDITIONER,
      "Janitorial services",
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 16 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: PRIVATE_OFFICE, capacity: 1 },
      { type: PRIVATE_OFFICE, capacity: 4 },
      { type: PRIVATE_OFFICE, capacity: 4 },
      { type: PRIVATE_OFFICE, capacity: 8 },
      { type: LOUNGE_AREA },
      { type: RECEPTION },
      { type: KITCHEN },
    ],
  },
  {
    listed: true,
    title: "HiOperator",
    slug: "hioperator-dallas",
    summary: `HiOperator has a 17000 sqft office space ideal for teams in all sizes looking for workspace in Dallas.
  
This fully furnished office has plenty of natural light and gives access to all the needed amenities that foster an inspiring work environment.`,
    shortAddress: "Northeast Dallas",
    address1: "8350 N Central Expy, Suite 500",
    address2: "Dallas, TX 75206",
    area: "dallas",
    cover: "/spaces/hioperator-dallas/cover.jpg",
    images: Array.from({ length: 0 }).map((_, n) => ({
      url: `/spaces/hioperator-dallas/${n}.jpg`,
    })),
    coords: [32.863002380950306, -96.7689456309948],
    host: {
      name: "HiOperator",
      batch: "S16",
      avatarUrl: "/logos/hioperator.png",
      url: "https://hioperator.com/",
      about: "HiOperator provides customer support as a service.",
      tags: ["Software", "AI", "Customer service"],
      joinDate: "2021-08-18",
      hiringUrl: "https://hioperator.breezy.hr/",
      employeeCount: 200,
      contactPerson: {
        name: "Elizabeth Tsai",
        title: "Founder",
        imageUrl: "/spaces/hioperator-dallas/elizabeth-tsai.jpg",
      },
    },
    accessInstructions: "",
    amenities: [
      amenities.WIFI,
      amenities.WORKCHAIRS,
      amenities.STANDING_DESKS,
      amenities.ALL_DAY_ACCESS,
      amenities.MEETING_ROOMS,
      amenities.LOUNGE,
      amenities.PHONE_BOOTHS,
      amenities.FREE_BEVERAGES,
      amenities.WHITEBOARD,
      amenities.PRINTER,
      amenities.KITCHEN,
    ],
    rooms: [
      { type: OPEN_OFFICE_AREA, capacity: 10 },
      { type: MEETING_ROOM, capacity: 8 },
      { type: MEETING_ROOM, capacity: 4 },
      { type: PHONE_BOOTH, count: 1 },
      { type: PRIVATE_OFFICE },
      { type: LOUNGE_AREA },
      { type: KITCHEN },
    ],
    upcoming: true,
  },
];

const allListings = [...listings, ...ycCampListings].map((l) => {
  return {
    ...l,
    amenities: l.amenities ?? defaultAmenities,
    summary: l.summary == null ? null : marked(l.summary),
    summaryPlainText: l.summary == null ? null : stripMarkdown(l.summary),
    // Dither coords to hide real location until booked
    ditheredCoords:
      (l.coords ?? l.approximateCoords)?.map(
        (n) => n + (Math.random() - 0.5) * 0.001
      ) ?? null,
  };
});

export const merge = (listing) => {
  const config = allListings.find((c) => c.slug === listing.slug);
  // DB properties take precidence
  return { ...config, ...listing };
};

export default allListings;
