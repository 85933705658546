import React from "react";
import { useUser as useAuth0User } from "@auth0/nextjs-auth0";

const Context = React.createContext(null);

export const Provider = ({ children }) => {
  const [user, setUser] = React.useState(null);

  const fetchUser = React.useCallback(
    () =>
      fetch("/api/me")
        .then((res) => res.json())
        .then((user) => {
          setUser(user);

          process.env.NODE_ENV === "production" &&
            window.Cohere.identify(user.id, {
              displayName: user.name,
              email: user.email,
            });
        }),
    []
  );
  return (
    <Context.Provider value={[user, { fetch: fetchUser }]}>
      {children}
    </Context.Provider>
  );
};

export const useUser = () => {
  return React.useContext(Context);
};

export const withUser = (Component) => {
  const EnhancedComponent = (props) => {
    const [user, { fetch: fetchUser }] = useUser();
    const { user: auth0User } = useAuth0User();

    React.useEffect(() => {
      fetchUser();
    }, [fetchUser]);

    return (
      <Component
        {...props}
        user={user}
        auth0User={auth0User}
        fetchUser={fetchUser}
      />
    );
  };

  return EnhancedComponent;
};
