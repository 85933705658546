import React from "react";
import { merge as mergeListingConfig } from "./locations";

const Context = React.createContext(null);

const useUser = ({ onFetch }) => {
  const [user, setUser] = React.useState(null);
  const onFetchRef = React.useRef(onFetch);

  React.useEffect(() => {
    onFetchRef.current = onFetch;
  });

  const fetchUser = React.useCallback(
    () =>
      fetch("/api/host/me")
        .then((res) => res.json())
        .then((user) => {
          onFetchRef.current(user);
          setUser(user);
        }),
    []
  );

  const actions = { fetch: fetchUser };

  if (user == null) return [null, actions];

  return [
    {
      ...user,
      organization: {
        ...user.organization,
        listings: user.organization.listings.map(mergeListingConfig),
      },
    },
    actions,
  ];
};

const SELECTED_LISTING_CACHE_KEY = "host/selected-listing-id";

const useSelectedListing = () => {
  const [selectedListingId, setSelectedListingId] = React.useState(() => {
    try {
      return JSON.parse(
        window.localStorage.getItem(SELECTED_LISTING_CACHE_KEY)
      );
    } catch (e) {
      return null;
    }
  });

  React.useEffect(() => {
    // Cache most recently selected listing
    try {
      window.localStorage.setItem(
        SELECTED_LISTING_CACHE_KEY,
        JSON.stringify(selectedListingId)
      );
    } catch (e) {
      // OK
    }
  }, [selectedListingId]);

  return [selectedListingId, setSelectedListingId];
};

export const Provider = ({ children }) => {
  const [selectedListingId, setSelectedListingId] = useSelectedListing();

  const [user, { fetch: fetchUser }] = useUser({
    onFetch: ({ organization: { listings } }) => {
      const hasValidListingSelect =
        listings.find((l) => l.id === selectedListingId) != null;

      if (hasValidListingSelect) return;

      setSelectedListingId(listings[0].id);
    },
  });

  return (
    <Context.Provider
      value={[
        { user, listings: user?.organization.listings, selectedListingId },
        { fetchUser, onSelectListing: setSelectedListingId },
      ]}
    >
      {children}
    </Context.Provider>
  );
}

export const useHostState = () => {
  return React.useContext(Context);
};
